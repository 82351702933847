import Box from "@mui/material/Box";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { AppendIfPrefixExists } from "../../../../../../modules/sitePaths";
import { assessmentTabIds } from "@features/assessments-feature/constants";
import React from "react";
import { ResponseCapability } from "@lib/ShiOneClient";
import { maturityLevelColors } from "@features/assessments-feature/utils/assessmentsColors";
import { twoLineHeightCell } from "../../../../theme/mixins/lineHeight";
import { useNavigate } from "react-router-dom";
import MaturityLabelWithIcon from "@features/assessments-feature/components/maturity-chart/MaturityLabelWithIcon";
import ToolsTypeIcon from "@features/assessments-feature/components/ToolsTypeIcon";
import { ToolsType } from "@features/assessments-feature/types/PlatformInsightsProductsType";
import {
  PrintOrientation,
  useAssessmentExportContext,
} from "@features/assessments-feature/contexts-providers/AssessmentExportContextProvider";
import { useAssessmentViewContext } from "@features/assessments-feature/contexts-providers/AssessmentViewContextProvider";

export default function MaturityChartCapability({
  capability,
}: {
  capability: ResponseCapability;
}) {
  const navigate = useNavigate();
  const { program } = useAssessmentViewContext();
  const { isPrint, printLayout } = useAssessmentExportContext();

  return (
    <Grid
      item
      xs={!isPrint}
      minWidth={isPrint ? 160 : 200}
      maxWidth={
        isPrint && printLayout === PrintOrientation.Landscape ? 220 : 240
      }
    >
      <Box
        className={"maturity-card"}
        bgcolor={"background.paper"}
        padding={1}
        pb={isPrint ? 1 : 3}
        sx={{
          borderLeft: `8px solid ${
            maturityLevelColors[capability?.maturity ?? ""]
          }`,
          height: "100%",
        }}
      >
        <MaturityLabelWithIcon maturityValue={capability.maturity ?? ""} />
        <Tooltip title={capability.name} followCursor>
          <Typography gutterBottom variant={"body1"} sx={twoLineHeightCell}>
            {!!capability.name ? capability.name : "N/A"}
          </Typography>
        </Tooltip>
        <Typography
          gutterBottom
          component="div"
          variant={"body1"}
          display={"flex"}
          alignItems={"center"}
          color={"textSecondary"}
        >
          <ToolsTypeIcon
            showTooltip
            type={ToolsType.KnownTools}
            sx={{ fontSize: 16, mr: 0.5 }}
          />

          {capability.tools?.length !== 0
            ? capability.tools?.join(" / ")
            : "None"}
        </Typography>
        {capability.hasRecommendation && capability.name && !isPrint && (
          <Button
            variant={"text"}
            size={"small"}
            onClick={() =>
              navigate(
                AppendIfPrefixExists(
                  `/assessments/${program}/${assessmentTabIds.recommendations}`,
                ),
                {
                  state: { capability: capability.name, openRecsDialog: true },
                },
              )
            }
          >
            View Recommendation
          </Button>
        )}
      </Box>
    </Grid>
  );
}

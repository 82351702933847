import {
  AssetManagementDto,
  AwsComplianceIntegrationDTO,
  AwsComplianceIntegrationMetadata,
  AwsGovernanceReportsDto,
  AwsResourceExplorerIntegrationDTO,
  AwsResourceExplorerMetadata,
  AwsSimpleRecommendationsIntegrationDTO,
  AwsSimpleRecommendationsIntegrationMetadata,
  AzureGovernanceReportsDto,
  CiscoMerakiCustomerIntegrationDto,
  CiscoMerakiCustomerMetadata,
  DatadogUsageReportIntegrationDTO,
  DatadogUsageReportIntegrationMetadata,
  GcpCloudAssetInventoryIntegrationDTO,
  GcpCloudAssetInventoryMetadata,
  HardwareLifecycleIntegrationMetadata,
  HardwareLifecycleManagementIntegrationDTO,
  IntegrationDTO,
  IntegrationType,
  Microsoft365ReportsIntegrationDTO,
  Microsoft365ReportsIntegrationMetadata,
  SelfServiceReportingIntegrationDto,
  SelfServiceReportingIntegrationMetadata,
} from "lib/ShiOneClient";

function createAwsSimpleRecommendationsIntegrationDTO(
  integrationObject: any,
): AwsSimpleRecommendationsIntegrationDTO {
  const integrationMetadata: AwsSimpleRecommendationsIntegrationMetadata =
    new AwsSimpleRecommendationsIntegrationMetadata();
  integrationMetadata.roleArn = integrationObject.metadata.roleArn;
  integrationMetadata.externalId = integrationObject.metadata.externalId;
  integrationMetadata.payerAccountId =
    integrationObject.metadata.roleArn.match("[1-9][0-9]{0,11}")[0];

  const AwsIntegrationDTO: AwsSimpleRecommendationsIntegrationDTO =
    new AwsSimpleRecommendationsIntegrationDTO(integrationObject);
  AwsIntegrationDTO.metadata = integrationMetadata;
  AwsIntegrationDTO.accountId = integrationObject.accountId;
  AwsIntegrationDTO.activated = true;

  return AwsIntegrationDTO;
}

function createAwsComplianceIntegrationDTO(
  integrationObject: any,
): AwsComplianceIntegrationDTO {
  const integrationMetadata: AwsComplianceIntegrationMetadata =
    new AwsComplianceIntegrationMetadata();
  integrationMetadata.roleArn = integrationObject.metadata.roleArn;
  integrationMetadata.externalId = integrationObject.metadata.externalId;
  integrationMetadata.accountId =
    integrationObject.metadata.roleArn.match("[1-9][0-9]{0,11}")[0];

  const AwsIntegrationDTO: AwsComplianceIntegrationDTO =
    new AwsComplianceIntegrationDTO(integrationObject);
  AwsIntegrationDTO.metadata = integrationMetadata;
  AwsIntegrationDTO.accountId = integrationObject.accountId;
  AwsIntegrationDTO.activated = true;

  return AwsIntegrationDTO;
}

function createAwsResourceExplorerIntegrationDTO(
  integrationObject: any,
): AwsResourceExplorerIntegrationDTO {
  const integrationMetadata: AwsResourceExplorerMetadata =
    new AwsResourceExplorerMetadata();
  integrationMetadata.roleArn = integrationObject.metadata.roleArn;
  integrationMetadata.externalId = integrationObject.metadata.externalId;

  const AwsResourceIntegrationDTO: AwsResourceExplorerIntegrationDTO =
    new AwsResourceExplorerIntegrationDTO(integrationObject);
  AwsResourceIntegrationDTO.metadata = integrationMetadata;
  AwsResourceIntegrationDTO.accountId = integrationObject.accountId;
  AwsResourceIntegrationDTO.activated = true;
  return AwsResourceIntegrationDTO;
}

function createAssetManagementIntegrationDTO(
  integrationObject: any,
): AssetManagementDto {
  const assetManagementIntegration = new AssetManagementDto(integrationObject);
  assetManagementIntegration.metadata = integrationObject.metadata;
  assetManagementIntegration.integrationType = IntegrationType.AssetManagement;
  return assetManagementIntegration;
}

function createAwsGovernanceReportsIntegrationDTO(
  integrationObject: any,
): AwsGovernanceReportsDto {
  const awsGovernanceReportsIntegration = new AwsGovernanceReportsDto(
    integrationObject,
  );
  awsGovernanceReportsIntegration.metadata = integrationObject.metadata;
  return awsGovernanceReportsIntegration;
}

function createAzureGovernanceReportsIntegrationDTO(
  integrationObject: any,
): AzureGovernanceReportsDto {
  const azureGovernanceReportsIntegration = new AzureGovernanceReportsDto(
    integrationObject,
  );
  azureGovernanceReportsIntegration.metadata = integrationObject.metadata;
  return azureGovernanceReportsIntegration;
}

function createDatadogUsageReportIntegrationDTO(
  integrationObject: any,
): DatadogUsageReportIntegrationDTO {
  const integrationMetadata: DatadogUsageReportIntegrationMetadata =
    new DatadogUsageReportIntegrationMetadata();
  integrationMetadata.axAccount = integrationObject.metadata.axAccount;

  const DatadogIntegrationDTO: DatadogUsageReportIntegrationDTO =
    new DatadogUsageReportIntegrationDTO(integrationObject);
  DatadogIntegrationDTO.metadata = integrationMetadata;
  DatadogIntegrationDTO.accountId = integrationObject.accountId;
  DatadogIntegrationDTO.activated = true;

  return DatadogIntegrationDTO;
}

function createHardwareLifecycleManagementIntegrationDTO(
  integrationObject: any,
): HardwareLifecycleManagementIntegrationDTO {
  const integrationMetadata: HardwareLifecycleIntegrationMetadata =
    new HardwareLifecycleIntegrationMetadata();
  integrationMetadata.deviceMakeModel =
    integrationObject.metadata.deviceMakeModel;

  const dto: HardwareLifecycleManagementIntegrationDTO =
    new HardwareLifecycleManagementIntegrationDTO(integrationObject);
  dto.metadata = integrationMetadata;
  dto.accountId = integrationObject.accountId;
  dto.activated = true;

  return dto;
}

function createSelfServiceReportingIntegrationDto(
  integrationObject: any,
): SelfServiceReportingIntegrationDto {
  const integrationMetadata: SelfServiceReportingIntegrationMetadata =
    new SelfServiceReportingIntegrationMetadata();
  integrationMetadata.axAccount = integrationObject?.metadata?.axAccount;
  integrationMetadata.workspaceId = integrationObject?.metadata?.workspaceId;
  integrationMetadata.accountName = integrationObject?.metadata?.accountName;
  integrationMetadata.datasetCategories =
    integrationObject?.metadata?.datasetCategories;

  const dto: SelfServiceReportingIntegrationDto =
    new SelfServiceReportingIntegrationDto(integrationObject);
  dto.metadata = integrationMetadata;
  dto.accountId = integrationObject.accountId;
  dto.axAccountNumber = integrationObject.axAccountNumber;
  dto.activated = true;

  return dto;
}

function createGcpCloudAssetInventoryDto(
  integrationObject: any,
): GcpCloudAssetInventoryIntegrationDTO {
  const integrationMetadata: GcpCloudAssetInventoryMetadata =
    new GcpCloudAssetInventoryMetadata();
  integrationMetadata.authorizationScope =
    integrationObject.metadata.authorizationScope;
  integrationMetadata.serviceAccountKey =
    integrationObject.metadata.serviceAccountKey;

  const GcpCloudAssetInventoryDto: GcpCloudAssetInventoryIntegrationDTO =
    new GcpCloudAssetInventoryIntegrationDTO(integrationObject);
  GcpCloudAssetInventoryDto.metadata = integrationMetadata;
  GcpCloudAssetInventoryDto.accountId = integrationObject.accountId;
  GcpCloudAssetInventoryDto.activated = true;
  return GcpCloudAssetInventoryDto;
}

function createMicrosoft365ReportsIntegrationDto(
  integrationObject: any,
): Microsoft365ReportsIntegrationDTO {
  const integrationMetadata: Microsoft365ReportsIntegrationMetadata =
    new Microsoft365ReportsIntegrationMetadata();
  integrationMetadata.domainName = integrationObject.metadata.domainName;
  integrationMetadata.tenantId = integrationObject.metadata.tenantId;
  integrationMetadata.shiRegistrationGuid =
    integrationObject.metadata.shiRegistrationGuid;

  const microsoft365ReportsIntegrationDto: Microsoft365ReportsIntegrationDTO =
    new Microsoft365ReportsIntegrationDTO(integrationObject);
  microsoft365ReportsIntegrationDto.metadata = integrationMetadata;
  microsoft365ReportsIntegrationDto.activated = true;
  microsoft365ReportsIntegrationDto.accountId = integrationObject.accountId;
  console.log(integrationObject);
  console.log(microsoft365ReportsIntegrationDto);
  return microsoft365ReportsIntegrationDto;
}

function createCiscoMerakiCustomerIntegrationDto(
  integrationObject: any,
): CiscoMerakiCustomerIntegrationDto {
  const integrationMetadata: CiscoMerakiCustomerMetadata =
    new CiscoMerakiCustomerMetadata();
  integrationMetadata.friendlyKeyName =
    integrationObject.metadata.friendlyKeyName;
  integrationMetadata.customerApiKey =
    integrationObject.metadata.customerApiKey;

  const CiscoMerakiCustomerDto: CiscoMerakiCustomerIntegrationDto =
    new CiscoMerakiCustomerIntegrationDto(integrationObject);
  CiscoMerakiCustomerDto.metadata = integrationMetadata;
  CiscoMerakiCustomerDto.accountId = integrationObject.accountId;
  CiscoMerakiCustomerDto.activated = true;
  return CiscoMerakiCustomerDto;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function mapByIntegrationType(
  integrationObject: any,
  integrationType: IntegrationType | undefined,
): IntegrationDTO | undefined {
  switch (integrationType) {
    case IntegrationType.AwsSimpleRecommendations:
      return createAwsSimpleRecommendationsIntegrationDTO(integrationObject);
    case IntegrationType.AwsCompliance:
      return createAwsComplianceIntegrationDTO(integrationObject);
    case IntegrationType.AwsResourceExplorer:
      return createAwsResourceExplorerIntegrationDTO(integrationObject);
    case IntegrationType.AzureGovernanceReports:
      return createAzureGovernanceReportsIntegrationDTO(integrationObject);
    case IntegrationType.DatadogUsageReport:
      return createDatadogUsageReportIntegrationDTO(integrationObject);
    case IntegrationType.HardwareLifecycleManagement:
      return createHardwareLifecycleManagementIntegrationDTO(integrationObject);
    case IntegrationType.AwsGovernanceReports:
      return createAwsGovernanceReportsIntegrationDTO(integrationObject);
    case IntegrationType.AssetManagement:
      return createAssetManagementIntegrationDTO(integrationObject);
    case IntegrationType.SelfServiceReporting:
      return createSelfServiceReportingIntegrationDto(integrationObject);
    case IntegrationType.GcpCloudAssetInventory:
      return createGcpCloudAssetInventoryDto(integrationObject);
    case IntegrationType.Microsoft365Reports:
      return createMicrosoft365ReportsIntegrationDto(integrationObject);
    case IntegrationType.CiscoMerakiCustomer:
      return createCiscoMerakiCustomerIntegrationDto(integrationObject);
    default:
      return undefined;
  }
}

import { ChangeRequestDialog } from "../ChangeRequestDialog";
import { Button } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import React from "react";

const IdcsChangeRequestButton = () => {
  const [changeRequestDialogVisible, setChangeRequestDialogVisible] =
    React.useState(false);

  const handleSetChangeRequestDialogVisible = async () => {
    setChangeRequestDialogVisible(true);
  };

  return (
    <>
      <ChangeRequestDialog
        openDialog={changeRequestDialogVisible}
        closeDialog={() => setChangeRequestDialogVisible(false)}
      ></ChangeRequestDialog>
      <Button
        variant="outlined"
        color="inherit"
        startIcon={<Visibility />}
        onClick={handleSetChangeRequestDialogVisible}
      >
        IDCS Change Request
      </Button>
    </>
  );
};

export default IdcsChangeRequestButton;

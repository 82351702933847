import React from "react";
import { IconButton, Typography, Grid, Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { NavLink } from "react-router-dom";
import ChevronRight from "@mui/icons-material/ChevronRight";

const css = () => ({
  headerChevron: {
    color: "text.secondary",
    width: "18px",
    height: "18px",
  },
});

export default function PageHeader({
  header,
  subheader = "",
  icon,
  headerComponents,
  backButtonUrl,
  backButtonAction,
  backButtonAriaLabel,
}: Readonly<{
  header: string | string[];
  subheader?: React.ReactNode;
  icon?: any;
  headerComponents?: any;
  backButtonUrl?: string;
  backButtonAction?: () => void;
  backButtonAriaLabel?: string;
}>) {
  const classes = css();

  const handleHeader = (header: string | string[]) => {
    if (Array.isArray(header) && header.length > 0) {
      return header.map((element) => {
        if (header.indexOf(element) !== header.length - 1) {
          return [
            element,
            <ChevronRight key="arrow" sx={classes.headerChevron} />,
          ];
        } else return element;
      });
    }
    return header;
  };

  return (
      <Grid
        container
        justifyContent="space-between"
        columnGap={5}
        rowGap={2}
        paddingBottom={3}
      >
        <Grid item xs={12} md container minHeight={6} color={"text.secondary"}>
          <Grid item xs={12} md={"auto"}>
            {backButtonUrl && (
              <IconButton
                color="inherit"
                component={NavLink}
                to={backButtonUrl}
                aria-label={backButtonAriaLabel}
                size="large"
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
            {backButtonAction && (
              // @ts-ignore
              <IconButton
                color="inherit"
                onClick={() => backButtonAction()}
                aria-label={backButtonAriaLabel}
                size="large"
              >
                <ChevronLeftIcon />
              </IconButton>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md
            container
            alignItems={"center"}
            wrap={"nowrap"}
            gap={2}
            pl={1}
          >
            {icon && <Grid item>{icon}</Grid>}
            <Grid item>
              <Typography component="div" variant="h6" color={"text.primary"}>
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  {handleHeader(header)}
                </Box>
              </Typography>
              {subheader && (
                <Typography component="div" variant="body2">
                  {subheader}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={"auto"} marginLeft={"auto"} alignSelf={"center"}>
          <Box>
            {headerComponents}
          </Box>
        </Grid>
      </Grid>
  );
}

import {
  CreateIdcsChangeRequestDto,
  IdcsClient,
} from "../../../../../customer-experience/src/lib/ShiOneClient";
import { ApiClientConfig } from "../../../../../customer-experience/src/auth/ApiClientConfig";

const api = new IdcsClient(ApiClientConfig, import.meta.env.API_ENDPOINT);

const createIdcsChangeRequest = async (request: CreateIdcsChangeRequestDto) => {
  try {
    await api.createIdcsChangeRequest(request);
    return true;
  } catch (error) {
    return false;
  }
};

export default createIdcsChangeRequest;

import shiColors, { goodToBadColors } from "shared-ui/src/theme/shiColors";

export const capabilityAreasColors = [
  ...Object.values(goodToBadColors).slice(0, -1),
  shiColors.gray.light,
];

export const impactColor: { [key: string]: string } = {
  "high impact": shiColors.red.DEFAULT,
  "low impact": shiColors.orange.lighter,
};

export const maturityLevelColors: { [key: string]: string } = {
  Optimized: goodToBadColors.best,
  Operational: goodToBadColors.good,
  Installed: goodToBadColors.ok,
  Functional: goodToBadColors.ok,
  Evaluating: goodToBadColors.warning,
  Gap: goodToBadColors.bad,
  Unknown: shiColors.gray.light,
};

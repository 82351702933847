import { ShiOnePermission } from "../../../../auth/authorizationTypes";
import { CloudAccount } from "@lib/ShiOneClient";
import _ from "lodash";
import {
  assetItemsToShowType,
  ciscoItemsToShowType,
  cloudAwsItemsToShowType,
  cloudAzureItemsToShowType,
  cloudDatadogItemsToShowType,
  cloudGcpItemsToShowType,
  cloudOffice365ItemsToShowType,
  hasLegacyPermissionType,
  hasPermissionType,
  hasRoleInType,
  homeItemsToShowType,
  servicesItemsToShowType,
  settingsItemsToShowType,
  supportItemsToShowType,
} from "./NavItemsToShowTypes";

export const homeItemsToShow = (
  hasPermission: hasPermissionType,
  hasLegacyPermission: hasLegacyPermissionType,
  hasCloudReports: boolean,
  isAdmin: boolean,
): homeItemsToShowType => {
  return {
    showMonitoring:
      hasPermission(ShiOnePermission.azureMonitoringAlertRead) ||
      hasPermission(ShiOnePermission.awsMonitoringAlertRead),
    showCloudConsumption:
      hasCloudReports &&
      (isAdmin ||
        hasLegacyPermission(
          "dashboards/multicloudConsumption",
          "report:ReadReports",
        )),
  };
};

export const supportItemsToShow = (
  hasPermission: hasPermissionType,
  hasRoleIn: hasRoleInType,
  hasDevices: boolean,
): supportItemsToShowType => {
  return {
    showServiceReport: hasRoleIn("CustomerAdmin"),
    showMyDevices: !hasRoleIn("ShiImpersonateVirtualAdmin") && hasDevices,
    showKnowledgeBase: hasPermission(ShiOnePermission.knowledgeBaseRead),
  };
};

export const assetItemsToShow = (
  hasLegacyPermission: hasLegacyPermissionType,
  hasHardwareLifecycleManagementIntegrations: boolean,
  hasIdcsReportIntegrations: boolean,
  hasDattoRmmIntegration: boolean,
  hasCiscoIntegrations: boolean,
): assetItemsToShowType => {
  return {
    showAssetReports: hasLegacyPermission(
      "assets/estate",
      "assets:ReadAssetEstate",
    ),
    showManagedDevices:
      hasLegacyPermission("assets/managed", "assets:ReadManagedDevices") &&
      hasDattoRmmIntegration,
    showWarehouseStock: hasLegacyPermission(
      "assets/warehouse",
      "assets:ReadWarehouseInventory",
    ),
    showWarehouseOrders: hasLegacyPermission(
      "assets/warehouse",
      "assets:ReadWarehouseOrders",
    ),
    showHardwareLifecycleManagement:
      hasHardwareLifecycleManagementIntegrations &&
      hasLegacyPermission("assets/warehouse", "assets:ReadWarehouseOrders"),
    showIdcsReport:
      hasLegacyPermission(
        "assets/integrationCenter/reports",
        "report:ReadReports",
      ) && hasIdcsReportIntegrations,
    showCiscoOverview:
      hasCiscoIntegrations &&
      hasLegacyPermission("assets/estate", "assets:ReadAssetEstate"),
  };
};

export const ciscoItemsToShow = (
  hasLegacyPermission: hasLegacyPermissionType,
  isAdmin: boolean,
  hasCiscoIntegrations: boolean,
): ciscoItemsToShowType => {
  return {
    showCisco:
      (isAdmin || hasLegacyPermission("cisco/reports", "report:ReadReports")) &&
      hasCiscoIntegrations,
  };
};

export const cloudAwsItemsToShow = (
  hasPermission: hasPermissionType,
  cloudContracts: CloudAccount[],
  isAdmin: boolean,
  isGuestUser: boolean,
): cloudAwsItemsToShowType => {
  let hasAwsMasterPayerAccounts = false;

  if (cloudContracts && cloudContracts.length > 0) {
    cloudContracts.forEach((cc) => {
      if (
        cc.cloud === "AWS" &&
        cc.userDefinedFieldsJson &&
        cc.userDefinedFieldsJson !== "[]"
      ) {
        const awsPayerID = _.find(
          JSON.parse(cc.userDefinedFieldsJson),
          (udf) => {
            return udf.Name === "AWS Payer ID";
          },
        );
        if (awsPayerID && awsPayerID.Value) {
          hasAwsMasterPayerAccounts = true;
        }
      }
    });
  }
  return {
    showAccounts:
      (!isGuestUser && hasAwsMasterPayerAccounts) ||
      hasPermission(ShiOnePermission.awsAccountRead),
    showConsumption: hasPermission(ShiOnePermission.awsConsumptionRead),
    showBlueprints: hasPermission(ShiOnePermission.awsBlueprintDeploy),
    showCostAllocationTags: isAdmin && hasAwsMasterPayerAccounts,
    showInvoices: hasPermission(ShiOnePermission.awsInvoiceRead),
    showMonitoring: hasPermission(ShiOnePermission.awsMonitoringAlertRead),
    showRecommendations: hasPermission(ShiOnePermission.awsRecommendationRead),
    showGovernance: hasPermission(ShiOnePermission.awsComplianceRead),
  };
};

export const cloudAzureItemsToShow = ({
  hasPermission,
  hasAzureBackupIntegrations,
  hasAzureComplianceIntegrations,
  hasAzureGovernanceReportsIntegrations,
}: {
  hasPermission: hasPermissionType;
  hasAzureBackupIntegrations: boolean;
  hasAzureComplianceIntegrations: boolean;
  hasAzureGovernanceReportsIntegrations: boolean;
}): cloudAzureItemsToShowType => {
  return {
    showBackup:
      hasPermission(ShiOnePermission.azureBackupAlertRead) &&
      hasAzureBackupIntegrations,
    showBlueprints: hasPermission(ShiOnePermission.azureBlueprintDeploy),
    showInvoices: hasPermission(ShiOnePermission.azureInvoiceRead),
    showMonitoring: hasPermission(ShiOnePermission.azureMonitoringAlertRead),
    showRecommendations: hasPermission(
      ShiOnePermission.azureRecommendationRead,
    ),
    showAllUpRecommendations: hasPermission(
      ShiOnePermission.azureRecommendationRead,
    ),
    showReports: hasPermission(ShiOnePermission.azureConsumptionRead),
    showSecurityAndCompliance:
      hasPermission(ShiOnePermission.azureComplianceRead) &&
      hasAzureComplianceIntegrations,
    showSubscriptions: hasPermission(ShiOnePermission.azureAccountRead),
    showGovernance:
      hasPermission(ShiOnePermission.azureComplianceRead) &&
      hasAzureGovernanceReportsIntegrations,
  };
};

export const cloudGcpItemsToShow = ({
  hasPermission,
}: {
  hasPermission: hasPermissionType;
}): cloudGcpItemsToShowType => {
  return {
    showInvoices: hasPermission(ShiOnePermission.gcpInvoiceRead),
    showProjects: hasPermission(ShiOnePermission.gcpAccountRead),
    showReports: hasPermission(ShiOnePermission.gcpConsumptionRead),
  };
};

export const cloudOffice365ItemsToShow = (
  hasPermission: hasPermissionType,
  hasBackupService: boolean,
): cloudOffice365ItemsToShowType => {
  return {
    showBackup:
      hasPermission(ShiOnePermission.o365BackupStatusRead) && hasBackupService,
    showInvoices: hasPermission(ShiOnePermission.o365InvoiceRead),
    showSubscriptions: hasPermission(ShiOnePermission.o365AccountRead),
  };
};

export const cloudDatadogItemsToShow = (
  hasLegacyPermission: hasLegacyPermissionType,
  isAdmin: boolean,
  hasDatadogUsageIntegration: boolean,
  contosoDemo: boolean,
): cloudDatadogItemsToShowType => {
  return {
    showReports:
      ((isAdmin ||
        hasLegacyPermission("cloud/datadog/report", "report:ReadReports")) &&
        hasDatadogUsageIntegration) ||
      (isAdmin && contosoDemo),
  };
};

export const servicesItemsToShow = (
  hasPermission: hasPermissionType,
  isAdmin: boolean,
  fsaID: number | undefined,
  hasCatalyst: boolean,
): servicesItemsToShowType => {
  return {
    showContracts:
      isAdmin || hasPermission(ShiOnePermission.serviceContractRead),
    showAllProjects:
      isAdmin || hasPermission(ShiOnePermission.serviceProjectRead),
    showInvoices: isAdmin || hasPermission(ShiOnePermission.serviceInvoiceRead),
    showFlexibleServiceAgreement: isAdmin && !!fsaID,
    showCatalyst: hasCatalyst,
  };
};

export const settingsItemsToShow = (
  isAdmin: boolean,
  myManagedSecurityGroups: any[],
  hasCloudAccounts: boolean,
): settingsItemsToShowType => {
  return {
    showGeneral: isAdmin,
    showIntegrations: isAdmin,
    showUserManagement: isAdmin,
    showSecurityGroups: isAdmin || myManagedSecurityGroups.length > 0,
    showCostCenters: isAdmin && hasCloudAccounts,
  };
};

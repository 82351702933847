import React, { useCallback, useEffect } from "react";
import { Drawer, IconButton, useTheme } from "@mui/material";
import List from "@mui/material/List";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import GlobalConstants from "@constants";
import { getBackupStats } from "../../../modules/backup/actions/backupActions";
import {
  getIntegrationsCollection,
  selectVisibleAzureCloudReports,
} from "../../../modules/rtk/integrationsCollector/integrationsCollectorSlice";
import { getClientAxNumbers } from "../../../modules/clientManagement/actions/clientManagementActions";
import { DateTime } from "luxon";
import NavItem from "./Navigation/NavItem";
import { NavigationStyles } from "./styles/NavigationStyles";
import { getMyContracts } from "../../../modules/rtk/contract/contractSlice";
import CustomerNavigationItems from "./Navigation/CustomerNavigationItems";
import { useNavHamburger } from "../../../containers/useNavHamburger";
import { IRootState } from "../../../modules";
import { NavItemType } from "./Navigation/NavigationTypes";
import ShiNavigationItems from "./Navigation/ShiNavigationItems";
import ModuleSwitcher from "shared-ui/src/components/navigation/ModuleSwitcher";
import { useClaims } from "../../../auth/ClaimsProvider";
import { NavigationFeatureFlags } from "./Navigation/SubNavGroups/navigationFeatureFlags";
import { NavItemsProvider } from "./Navigation/NavItemContext";

export default function Navigation() {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, [dispatch]);
  const theme = useTheme();
  const styles = NavigationStyles(theme);
  const { isDesktop, isOpenSideBar, handleHamburgerClick } = useNavHamburger();
  const { hasRoleIn, hasPermission } = useClaims();

  const {
    cloudContractsFetchStatus,
    cloudContracts,
    flexibleServiceAgreements,
  } = useSelector((state: IRootState) => state.contract);

  const { logoUrl, subDomain } = useSelector(
    (state: IRootState) => state.site.labelInfo,
  );

  const integrationsCollection = useSelector(
    (state: IRootState) => state.integrationsCollection,
    shallowEqual,
  );
  const integrationsCollectorFetchStatus =
    integrationsCollection.integrationsCollectorFetchStatus;

  const backup = useSelector((state: IRootState) => state.backup);
  const { fetchingBackupStats, fetchBackupStatsComplete, backupStatsError } =
    backup;

  const { userProfileResponse, portalRole } = useSelector(
    (state: IRootState) => state.profile,
  );

  const myDevices = useSelector(
    (state: IRootState) => state.device.myDevices,
    shallowEqual,
  );
  const knowledgebase = useSelector(
    (state: IRootState) => state.knowledgebase,
    shallowEqual,
  );
  const accounts = useSelector(
    (state: IRootState) => state.account,
    shallowEqual,
  );
  const teamContracts = useSelector(
    (state: IRootState) => state.team.teamContracts,
  );
  const myTeams = useSelector(
    (state: IRootState) => state.teamRtk.myTeams,
    shallowEqual,
  );
  const {
    fetchingClientAxNumbers,
    fetchClientAxNumbersComplete,
    fetchClientAxNumbersError,
  } = useSelector((state: IRootState) => state.clientManagement);

  const hasAzureCloudReports = useSelector(selectVisibleAzureCloudReports);

  const featureFlags: NavigationFeatureFlags = {
    isCustomerSuccessEnabled: true,
  };

  const isShi = hasRoleIn("ShiUser", "ShiAdmin", "ShiResource");

  const isVirtualAdmin = hasRoleIn("ShiImpersonateVirtualAdmin");

  useEffect(() => {
    if (
      !fetchingBackupStats &&
      !fetchBackupStatsComplete &&
      !backupStatsError
    ) {
      stableDispatch(
        // @ts-ignore
        getBackupStats(
          DateTime.utc().toISO(),
          DateTime.utc().minus({ months: 6 }).toISO(),
        ),
      );
    }
  }, [
    fetchingBackupStats,
    fetchBackupStatsComplete,
    backupStatsError,
    stableDispatch,
  ]);

  useEffect(() => {
    if (integrationsCollectorFetchStatus === "idle") {
      // @ts-ignore
      stableDispatch(getIntegrationsCollection());
    }
  }, [integrationsCollectorFetchStatus, stableDispatch]);

  useEffect(() => {
    if (
      !fetchingClientAxNumbers &&
      !fetchClientAxNumbersComplete &&
      !fetchClientAxNumbersError
    ) {
      stableDispatch(
        // @ts-ignore
        getClientAxNumbers(userProfileResponse.userProfile?.contact?.accountID),
      );
    }
  }, [
    fetchingClientAxNumbers,
    fetchClientAxNumbersComplete,
    fetchClientAxNumbersError,
    stableDispatch,
    userProfileResponse.userProfile?.contact?.accountID,
  ]);

  useEffect(() => {
    if (cloudContractsFetchStatus === GlobalConstants.fetchStatus.idle) {
      // @ts-ignore
      stableDispatch(getMyContracts());
    }
  }, [cloudContractsFetchStatus, stableDispatch]);

  let navItems: (NavItemType | undefined)[];

  if (isShi) {
    navItems = ShiNavigationItems({
      userProfileResponse: userProfileResponse,
      isShiUser: isShi,
      portalRole: portalRole,
      integrationsCollection: integrationsCollection,
      accounts: accounts,
      teamContracts: teamContracts,
      myTeams: myTeams,
      knowledgebase: knowledgebase,
      myDevices: myDevices,
      cloudContracts: cloudContracts,
      fsas: flexibleServiceAgreements,
      backup: backup,
      hasAzureCloudReports: hasAzureCloudReports,
      subDomain: subDomain,
      featureFlags: featureFlags,
      hasPermission: hasPermission,
    });
  } else {
    navItems = CustomerNavigationItems({
      integrationsCollection,
      teamContracts,
      myTeams,
      knowledgebase,
      cloudContracts,
      backup,
      hasAzureCloudReports,
      subDomain,
      fsas: flexibleServiceAgreements,
    });
  }

  return (
    <NavItemsProvider>
      <Drawer
        anchor="left"
        sx={styles.drawer}
        open={isOpenSideBar}
        variant={isDesktop ? "persistent" : "temporary"}
        data-telemetry-name="Navigation Area"
      >
        {!isDesktop && (
          <div style={styles.navHeader}>
            <div style={styles.navAction as React.CSSProperties}>
              <IconButton
                onClick={handleHamburgerClick}
                sx={styles.menuButton}
                size="large"
              >
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <div style={styles.navLogo}>
              <img alt="Logo" src={logoUrl} style={styles.navImage} />
            </div>
          </div>
        )}
        {(isShi || isVirtualAdmin) && (
          <ModuleSwitcher
            pageName={GlobalConstants.portalName.customerExperience}
          />
        )}
        <List component="nav" sx={styles.list}>
          {navItems &&
            navItems.length > 0 &&
            navItems.map(
              (navItem, i) =>
                navItem && (
                  <NavItem key={navItem.id} navItem={navItem} tier={1} />
                ),
            )}
        </List>
      </Drawer>
    </NavItemsProvider>
  );
}

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface NavGroupContextType {
  openGroups: Record<string, boolean>;
  toggleGroup: (groupId: string) => void;
  setActiveGroup: (group: string, isOpen: boolean) => void;
}

const NavItemContext = createContext<NavGroupContextType>({
  openGroups: {},
  toggleGroup: () => {},
  setActiveGroup: () => {},
});

type NavGroupProviderProps = {
  children: ReactNode;
};

export const NavItemsProvider = ({ children }: NavGroupProviderProps) => {
  const [openGroups, setOpenGroups] = useState<Record<string, boolean>>({});

  const toggleGroup = useCallback((groupId: string) => {
    setOpenGroups((prev) => ({
      ...prev,
      [groupId]: !prev[groupId],
    }));
  }, []);

  const setActiveGroup = useCallback((groupId: string, isOpen: boolean) => {
    setOpenGroups((prev) => ({
      ...prev,
      [groupId]: isOpen,
    }));
  }, []);

  const value = useMemo(
    () => ({ openGroups, toggleGroup, setActiveGroup }),
    [openGroups, toggleGroup, setActiveGroup],
  );

  return (
    <NavItemContext.Provider value={value}>{children}</NavItemContext.Provider>
  );
};

export const useNavGroup = () => {
  const context = useContext(NavItemContext);
  if (!context) {
    throw new Error("useNavGroup must be used within a NavGroupProvider");
  }
  return context;
};

import { Grid, Typography } from "@mui/material";
import React from "react";
import { AssessmentProgram, ResponseSubcategory } from "@lib/ShiOneClient";
import MaturityChartCapability from "@features/assessments-feature/components/maturity-chart/MaturityChartCapability";
import { useAssessmentExportContext } from "@features/assessments-feature/contexts-providers/AssessmentExportContextProvider";

export default function MaturityChartSubcategory({
  subcategory,
  assessmentProgram,
}: {
  subcategory: ResponseSubcategory;
  assessmentProgram?: AssessmentProgram;
}) {
  const { isPrint } = useAssessmentExportContext();
  const tilePadding = isPrint ? 1 : 2;

  return (
    <Grid
      container
      flexDirection={"column"}
      flexWrap={"nowrap"}
      bgcolor={"background.default"}
      sx={{
        height: "100%",
        width: "100%",
        outline: isPrint ? "0.8px gray solid" : undefined,
      }}
    >
      {assessmentProgram !== AssessmentProgram.DevOpsPostureReview &&
        assessmentProgram !== AssessmentProgram.MicrosoftAdoptionAssessment && (
          <Grid item>
            <Typography
              variant={"body1"}
              align={"center"}
              color={"textSecondary"}
              py={isPrint ? 1 : 3}
              fontWeight={isPrint ? 500 : 400}
            >
              {subcategory.name}
            </Typography>
          </Grid>
        )}
      <Grid
        item
        container
        gap={tilePadding}
        sx={{ px: tilePadding, pb: tilePadding, height: "100%" }}
        py={
          assessmentProgram === AssessmentProgram.DevOpsPostureReview ||
          assessmentProgram === AssessmentProgram.MicrosoftAdoptionAssessment
            ? isPrint
              ? 1
              : 2
            : 0
        }
      >
        {subcategory?.responseCapabilities?.map((capability, index) => {
          const key = (capability.name ?? "") + "-" + index;
          return <MaturityChartCapability key={key} capability={capability} />;
        })}
      </Grid>
    </Grid>
  );
}

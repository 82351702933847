import React, { Fragment, useEffect, useMemo } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NavItem from "./NavItem";
import { NavGroupStyles } from "../styles/NavigationStyles";
import { NavItemType } from "./NavigationTypes";
import { useLocation } from "react-router-dom";
import { useNavGroup } from "./NavItemContext";
import { updateActiveNavGroups } from "./NavItemsHelpers";

export interface NavGroupProps {
  navItem: NavItemType;
  tier: number;
  parentContext: string;
}

export default function NavGroup({
  navItem,
  tier,
  parentContext,
}: NavGroupProps) {
  const theme = useTheme();
  const styles = NavGroupStyles(theme);
  const location = useLocation();
  const { openGroups, toggleGroup, setActiveGroup } = useNavGroup();
  const isOpen = openGroups[navItem.id];

  const isGroupActive = useMemo(() => {
    if (navItem.children) {
      return updateActiveNavGroups(
        navItem.id,
        navItem.children,
        location.pathname,
        openGroups,
      );
    }
    return false;
  }, [location.pathname]);

  useEffect(() => {
    setActiveGroup(navItem.id, isGroupActive);
  }, [isGroupActive, navItem.id]);

  const handleClick = () => {
    toggleGroup(navItem.id);
  };

  if (!navItem.visible) {
    return <Fragment />;
  }

  const telemetryContext = `${parentContext} > ${navItem.displayName}`;

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        data-telemetry-name={telemetryContext}
      >
        {navItem.icon && (
          <ListItemIcon sx={styles.listItemIcon}>{navItem.icon}</ListItemIcon>
        )}
        <ListItemText
          primary={navItem.displayName}
          sx={[
            tier === 1 ? styles.listItemText : {},
            tier === 2 ? styles.tier2 : {},
          ]}
          title={navItem.displayName}
        />
        {isOpen ? (
          <ExpandLess color={"action"} />
        ) : (
          <ExpandMore color={"action"} />
        )}
      </ListItemButton>
      {navItem.children && navItem.children.length > 0 && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {navItem.children.map((child, i) => {
              if (child) {
                return (
                  <NavItem
                    key={i}
                    navItem={child}
                    tier={tier + 1}
                    parentContext={telemetryContext}
                  />
                );
              }
            })}
          </List>
        </Collapse>
      )}
    </>
  );
}

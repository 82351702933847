import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FilePond } from "react-filepond";
import createIdcsChangeRequest from "../hooks/createIdcsChangeRequest";
import {
  AirtableFileObject,
  CreateIdcsChangeRequestDto,
} from "../../../../../customer-experience/src/lib/ShiOneClient";
import SnackbarMessage from "../../../theme/extensions/SnackbarMessage";

type Props = {
  openDialog: boolean;
  closeDialog: () => void;
};

export const ChangeRequestDialog = ({ openDialog, closeDialog }: Props) => {
  const handleClose = () => {
    closeDialog();
  };

  const [fields, setFields] = useState<{
    requestorName: string;
    requestorEmail: string;
    projectName: string;
    projectIdentifier: string;
    changeType: string;
    changeDescription: string;
  }>({
    requestorName: "",
    requestorEmail: "",
    projectName: "",
    projectIdentifier: "",
    changeType: "",
    changeDescription: "",
  });
  const [files, setFiles] = useState<File[]>([]);
  const [disableButton, setDisableButton] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState<"success" | "error">(
    "success",
  );

  const everyFieldIsFilled = Object.values(fields).every(
    (field) => field !== "" && field !== undefined,
  );

  const changeTypes = [
    "SOW Changes",
    "Physical Configuration",
    "Logical Configuration",
    "Destination Change",
    "Date Change",
    "Other",
  ];

  const handleTextChange =
    (field: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
      setFields({
        ...fields,
        [field]: event.target.value as string,
      });
    };

  const handleSelectChange =
    (field: string) => (event: SelectChangeEvent<string>) => {
      setFields({
        ...fields,
        [field]: event.target.value as string,
      });
    };

  const handleSubmit = async () => {
    setDisableButton(true);
    try {
      const fileData = await convertFilesToRequestObject(files);
      const requestBody = {
        requestorName: fields.requestorName,
        requestorEmail: fields.requestorEmail,
        projectName: fields.projectName,
        projectIdentifier: fields.projectIdentifier,
        changeType: fields.changeType,
        changeDescription: fields.changeDescription,
        attachments: fileData,
      };
      await createIdcsChangeRequest(requestBody as CreateIdcsChangeRequestDto);
      handleSuccess();
    } catch (error) {
      handleError();
    } finally {
      handleClose();
      resetForm();
    }
  };

  const convertFilesToRequestObject = async (
    files: File[],
  ): Promise<AirtableFileObject[]> => {
    const fileDataPromises = files.map(async (file) => {
      const fileStream = file.stream();
      const reader = fileStream.getReader();
      const chunks: Uint8Array[] = [];
      let result;
      while (!(result = await reader.read()).done) {
        chunks.push(result.value);
      }
      const blob = new Blob(chunks);

      const base64String = await new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => resolve(fileReader.result as string);
        fileReader.onerror = reject;
        fileReader.readAsDataURL(blob);
      });

      const base64FileStream = base64String.split(",")[1];
      return new AirtableFileObject({
        fileName: file.name,
        contentType: file.type,
        base64FileStream: base64FileStream,
      });
    });

    return await Promise.all(fileDataPromises);
  };

  const handleSuccess = () => {
    setSnackbarMessage("Change request submitted successfully");
    setSnackbarVariant("success");
    setShowSnackbar(true);
  };

  const handleError = () => {
    setSnackbarMessage("Failed to submit change request");
    setSnackbarVariant("error");
    setShowSnackbar(true);
  };

  const resetForm = () => {
    setDisableButton(false);
    setFields({
      requestorName: "",
      requestorEmail: "",
      projectName: "",
      projectIdentifier: "",
      changeType: "",
      changeDescription: "",
    });
    setFiles([]);
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose} maxWidth={"md"} fullWidth>
        <DialogTitle>IDCS Change Request Form [SHIOne]</DialogTitle>
        <DialogContent dividers>
          <div className={"flex flex-col mt-3 gap-4"}>
            <TextField
              label={"Requestor Name"}
              value={fields.requestorName}
              required={true}
              onChange={handleTextChange("requestorName")}
            />
            <TextField
              label={"Requestor Email"}
              value={fields.requestorEmail}
              required={true}
              onChange={handleTextChange("requestorEmail")}
            />
            <div className={"gap-1 flex flex-col"}>
              <Typography>
                Please enter the name for the project that this request is for.
              </Typography>

              <TextField
                label={"Project Name"}
                value={fields.projectName}
                required={true}
                onChange={handleTextChange("projectName")}
                className={"w-full"}
              />
            </div>
            <div className={"gap-1 flex flex-col"}>
              <Typography>
                Please enter the specific project ID. Enter N/A if this request
                is for the entire project as a whole.
              </Typography>
              <TextField
                label={"Project Identifier"}
                value={fields.projectIdentifier}
                required={true}
                onChange={handleTextChange("projectIdentifier")}
                className={"w-full"}
              />
            </div>
            <div className={"flex flex-col gap-4"}>
              <Typography variant={"caption"}>
                SOW Changes: When a change to the language in the SOW is
                required. (Including but not limited to SLAs, adding services,
                removing services, etc.)
              </Typography>
              <Typography variant={"caption"}>
                Physical Configuration: When requirements to the physical build
                have changed. (Including but not limited to, adding or replacing
                one device with another which may impact the physical build
                including power or networking connections, dimensions and
                supported weight etc.)
              </Typography>
              <Typography variant={"caption"}>
                Logical Configuration: When requirements to the logical
                configuration of the build have changed. (Including but not
                limited to, update to the IP addresses, BIOS settings, firmware
                versions, installing OS, etc.)
              </Typography>
              <Typography variant={"caption"}>
                Destination Change: Use this for when a SHIP TO location address
                needs to be modified.
              </Typography>
              <Typography variant={"caption"}>
                Date Change: Use this for a requested delivery date change.
              </Typography>
              <Typography variant={"caption"}>
                Other: Any other changes that are required but are not listed
                under the above options.
              </Typography>
              <FormControl
                variant="outlined"
                sx={{ mb: 2, minWidth: 120 }}
                required={true}
              >
                <InputLabel id="dropdown-label">Change Type</InputLabel>
                <Select
                  labelId="dropdown-label"
                  value={fields.changeType}
                  onChange={handleSelectChange("changeType")}
                  label="Change Type"
                  required={true}
                >
                  {changeTypes.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <TextField
              label={"Change Description"}
              value={fields.changeDescription}
              required={true}
              onChange={handleTextChange("changeDescription")}
              multiline
              rows={4}
            />
            <FilePond
              files={files}
              onupdatefiles={(fileItems) => {
                setFiles(fileItems.map((fileItem) => fileItem.file as File));
              }}
              maxFileSize={"5MB"}
              maxFiles={4}
              allowMultiple={true}
              allowFileSizeValidation={true}
            />
            <Typography variant={"body2"}>
              Attachment Limitations: max 4 files, 5MB each.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!everyFieldIsFilled || disableButton}
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <SnackbarMessage variant={snackbarVariant} message={snackbarMessage} />
      </Snackbar>
    </>
  );
};

import { PageLayoutWidgetDto } from "@lib/ShiOneClient";
import { SkeletonWrapper, WidgetWrapper } from "shared-ui";
import { Box, ToggleButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LineChart, LineSeriesType } from "@mui/x-charts";
import ToggleButton from "@mui/material/ToggleButton";
import { frequencyOptionKeys } from "@widgets/utils/DateRanges";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import { useActiveUserTrendsWidget } from "./hooks/useActiveUserTrendsWidget";
import { MakeOptional } from "@mui/x-charts/internals";
import { servicesColors } from "@widgets/microsoft-adoption/utils/msftAdoptionColors";

export default function ActiveUserTrendsWidget({
  pageLayoutWidget,
}: Readonly<{
  pageLayoutWidget: PageLayoutWidgetDto;
}>) {
  const filterKey = "active-user-trends-widget";
  const { currentFilterValue, setFilter } = useWidgetFilterUserConfig(
    pageLayoutWidget.widgetId!,
    filterKey,
    frequencyOptionKeys.monthly,
  );

  const [series, setSeries] = useState<MakeOptional<LineSeriesType, "type">[]>([
    {
      data: [],
    },
  ]);
  const [xAxis, setXAxis] = useState<string[]>([""]);

  const { data, isLoading, isError } =
    useActiveUserTrendsWidget(currentFilterValue);

  useEffect(() => {
    if (data?.services) {
      const servicesData: MakeOptional<LineSeriesType, "type">[] =
        data?.services?.map((service) => {
          return {
            curve: "linear",
            label: service.displayName ?? "",
            data: service.activeUsersCounts ?? [0],
            color:
              service?.displayName &&
              servicesColors[service.displayName.toLowerCase()]
                ? servicesColors[service.displayName.toLowerCase()]
                : servicesColors["unknown"],
          };
        });
      setSeries(servicesData);
    }

    if (data?.activeUsersTimeframeLabels) {
      setXAxis(data?.activeUsersTimeframeLabels);
    }
  }, [data]);

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      error={isError}
      cardHeaderDivider
      noPadding={true}
      headerAction={
        <ToggleButtonGroup
          color="secondary"
          exclusive
          value={currentFilterValue}
          onChange={(e, value) => {
            if (value === null) return;
            setFilter(value);
          }}
        >
          {Object.keys(frequencyOptionKeys).map((key) => {
            return (
              <ToggleButton style={{ lineHeight: 1 }} key={key} value={key}>
                {key}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      }
    >
      <SkeletonWrapper loading={isLoading}>
        <Box sx={{ height: 300, paddingBottom: 2 }}>
          <LineChart
            series={series}
            xAxis={[
              {
                scaleType: "point",
                data: xAxis,
              },
            ]}
            grid={{ vertical: true, horizontal: true }}
            slotProps={{
              legend: {
                direction: "row",
                position: { vertical: "bottom", horizontal: "middle" },
                padding: 0,
                itemMarkWidth: 20,
                itemMarkHeight: 8,
                itemGap: 20,
                labelStyle: {
                  fontSize: 12,
                  fill: "gray",
                },
              },
            }}
          />
        </Box>
      </SkeletonWrapper>
    </WidgetWrapper>
  );
}

import { NavItemType } from "../../NavigationTypes";

export function CatalystMenu(hasCatalyst: boolean): NavItemType {
  return {
    id: "catalyst",
    type: "Link",
    displayName: "Catalyst",
    path: "/services/catalyst",
    visible: hasCatalyst,
  };
}

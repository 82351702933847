import React from "react";

export function ShadedCellContent({
  value,
  index,
  numberOfRows,
  reverse = false,
  rgbNumber,
  colorFadeout = true,
  additionalStyles,
}: {
  value: string | React.ReactElement;
  index: number;
  numberOfRows: number;
  reverse?: boolean;
  rgbNumber: string;
  colorFadeout?: boolean;
  additionalStyles?: React.CSSProperties;
}) {
  const generateFadeoutColor = () => {
    let ratio;
    if (reverse) {
      ratio = Number(index / numberOfRows) + 0.1;
    } else {
      ratio = 1 - Number(index / numberOfRows);
    }
    return `rgba(${rgbNumber}, ${(ratio * 0.2).toFixed(3)})`;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        backgroundColor: colorFadeout
          ? generateFadeoutColor()
          : `rgba(${rgbNumber}, 0.2)`,
        width: "100%",
        height: "100%",
        ...additionalStyles,
      }}
    >
      {value}
    </div>
  );
}

import React from "react";
import PrioritiesRecsView from "@features/assessments-feature/components/priorities-recommendations/PrioritiesRecsView";
import { assessmentTabViewMode } from "@features/assessments-feature/constants";
import { Box } from "@mui/material";

const PrioritiesRecsTableExport = () => {
  return (
    <Box
      className={"assessment-break"}
      sx={{
        zoom: "60%",
        "& button": {
          color: (theme) => theme.palette.text.primary,
          cursor: "default",
          userSelect: "text",
        },
        "& th": { minWidth: "3.5rem" },
        "& .MuiTableCell-root": { padding: 1 },
        "& .MuiList-root": { paddingBottom: 0 },
        "& .MuiListItem-root": { paddingTop: 0, paddingRight: 1 },
        WebkitPrintColorAdjust: "exact",
        printColorAdjust: "exact",
      }}
    >
      <PrioritiesRecsView viewMode={assessmentTabViewMode.table} />
    </Box>
  );
};

export default PrioritiesRecsTableExport;

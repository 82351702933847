import React from "react";
import { assessmentTabViewMode } from "@features/assessments-feature/constants";
import MaturityChartCards from "@features/assessments-feature/components/maturity-chart/MaturityChartCards";
import MaturityChartGrid from "@features/assessments-feature/components/maturity-chart/MaturityChartGrid";
import useGetAssessmentMaturityChart from "@features/assessments-feature/hooks/useGetAssessmentMaturityChart";
import LoadingOrError from "../../../../../../containers/LoadingOrError";
import { Skeleton } from "@mui/material";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";
import { useAssessmentViewContext } from "@features/assessments-feature/contexts-providers/AssessmentViewContextProvider";

const AssessmentMaturityChartView = ({
  viewMode,
}: {
  viewMode: assessmentTabViewMode;
}) => {
  const { assessmentViewProps, assessmentId } = useAssessmentViewContext();

  const assessmentProgram = assessmentViewProps?.id;
  const {
    data: assessmentData,
    isLoading,
    isError,
  } = useGetAssessmentMaturityChart(
    assessmentProgram,
    assessmentId ?? undefined,
  );
  const { isDemoMode } = useAssessmentDemoData();

  if (isError && assessmentProgram && !isDemoMode.get(assessmentProgram)) {
    return (
      <LoadingOrError error message={"There's an issue loading the page"} />
    );
  }

  if (isLoading && assessmentProgram && !isDemoMode.get(assessmentProgram)) {
    return (
      <>
        <Skeleton
          variant={"rectangular"}
          width={"100%"}
          height={320}
          sx={{ marginBottom: 2 }}
        />
        <Skeleton variant={"rectangular"} width={"100%"} height={320} />
      </>
    );
  }

  return (
    <>
      {viewMode === assessmentTabViewMode.card && (
        <MaturityChartCards
          responseCategories={assessmentData?.responseCategories}
          assessmentProgram={assessmentProgram}
        />
      )}

      {viewMode === assessmentTabViewMode.table && (
        <MaturityChartGrid
          responseCategories={assessmentData?.responseCategories}
          assessmentProgram={assessmentProgram}
        />
      )}
    </>
  );
};

export default AssessmentMaturityChartView;

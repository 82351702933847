import React from "react";
import AttachmentsField from "../containers/Fields/AttachmentsField";
import AlertWidget from "../containers/Widgets/AlertWidget";
import ContactsWidget from "../containers/Widgets/ContactsWidget";
import LocalDateTimeWidget from "../containers/Widgets/LocalDateTimeWidget";
import LocationsWidget from "../containers/Widgets/LocationsWidget";
import TimezoneWidget from "../containers/Widgets/TimezoneWidget";
import AccountDetailsField from "../containers/Fields/AccountDetailsField";
import BillingContactsField from "../containers/Fields/BillingContactsField";
import CurrentCustomerContactInformationField from "../containers/Fields/CurrentCustomerContactInformationField";
import DeviceMakeModelField from "../containers/Fields/DeviceMakeModelField";
import AWSAccountEmailWidget from "../containers/Widgets/AWSAccountEmailWidget";

import BillingAddressField from "../containers/Fields/BillingAddressField";
import LimitedTextAreaField from "../containers/Fields/LimitedTextAreaField";
import PrefixedCompanyNameWidget from "../containers/Widgets/PrefixedCompanyNameWidget";
import AllAccountsDetailsField from "../containers/Fields/AllAccountsDetailsField";
import Form from "@rjsf/mui";
import { customizeValidator } from "@rjsf/validator-ajv8";

import { localDateTimeValidator } from "../functions/dateHelper";
import palette from "../theme/palette";
import { AddressField } from "../containers/Fields/AddressField";
import { UnitedStatesAddressField } from "../containers/Fields/UnitedStatesAddressField";
import PrimaryContactField from "../containers/Fields/PrimaryContactField";
import { Box, useTheme } from "@mui/material";
import WarehousePicker from "containers/Fields/WarehousePicker";

const customWidgets = {
  alert: AlertWidget,
  awsAccountEmail: AWSAccountEmailWidget,
  prefixedCompanyName: PrefixedCompanyNameWidget,
  contacts: ContactsWidget,
  locations: LocationsWidget,
  timezones: TimezoneWidget,
  // Replace the default DateTimeWidget which saves data in UTC format with one that saves the literal text entered by the user.
  DateTimeWidget: LocalDateTimeWidget,
};

const customFormats = {
  datetime: localDateTimeValidator,
  "date-time": localDateTimeValidator,
  email: "^[\\w.%+-]+@[\\w.-]+\\.[a-zA-Z]{2,}$",
  optionalEmail: "^$|[\\w.%+-]+@[\\w.-]+\\.[a-zA-Z]{2,}$",
  awsPayerAccount: "^[0-9]{12}$",
};

const validator = customizeValidator({
  customFormats,
});

const withLabel = (Component, label) => (props) => {
  return <Component {...props} label={label} />;
};

// Wrap DeviceMakeModelField with the label
const DeviceMakeModelFieldWithLabel = withLabel(
  DeviceMakeModelField,
  "Device Make/Model",
);
const DeviceMakeModelToBeReturnedFieldWithLabel = withLabel(
  DeviceMakeModelField,
  "Device Make/Model To Be Shipped Back",
);

const customFields = {
  attachments: AttachmentsField,
  billingContacts: BillingContactsField,
  accountDetails: AccountDetailsField,
  allAccountDetails: AllAccountsDetailsField,
  billingAddress: BillingAddressField,
  limitedTextArea: LimitedTextAreaField,
  address: AddressField,
  currentCustomerContactInformation: CurrentCustomerContactInformationField,
  deviceMakeModel: DeviceMakeModelFieldWithLabel,
  deviceMakeModelToBeReturned: DeviceMakeModelToBeReturnedFieldWithLabel,
  primaryContact: PrimaryContactField,
  unitedStatesAddress: UnitedStatesAddressField,
  warehousePicker: WarehousePicker,
};

const css = (theme) => ({
  formContainer: {
    "& > form > div > fieldset": {
      border: 0,
      flex: 1,
      display: "contents",
    },
    "& .MuiGrid-spacing-xs-2": {
      marginTop: "-10px !important",
    },
    "& .MuiGrid-spacing-xs-2 > .MuiGrid-item": {
      paddingRight: 0,
    },
    "& legend": {
      overflow: "hidden",
    },
    "& .MuiTypography-h5": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontWeight: "500",
      fontSize: "1.3rem",
    },
    "& .MuiDivider-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiBox-root + MuiTypography-subtitle2": {
      marginTop: theme.spacing(1) * -1 + "px !important",
      marginBottom: theme.spacing(2),
    },
    "& .MuiTypography-subtitle2": {
      color: palette.text.secondary,
      fontWeight: "400",
      fontSize: "0.875rem",
      letterSpacing: "0.01071em",
      lineHeight: "1.5",
    },
  },
});

const ServiceRequestForm = (props) => {
  const theme = useTheme();
  const classes = css(theme);

  const {
    onSubmit,
    uiSchema,
    schema,
    validate,
    onChange,
    formData,
    submitFormButtonRef,
    disabled,
  } = props;

  return (
    <Box sx={classes.formContainer}>
      <Form
        id={"#serviceRequestForm"}
        onSubmit={onSubmit}
        uiSchema={uiSchema}
        schema={schema}
        validator={validator}
        validate={validate}
        onChange={onChange}
        showErrorList={false}
        formData={formData}
        disabled={disabled}
        fields={customFields}
        widgets={customWidgets}
        customFormats={customFormats}
        formContext={formData}
      >
        <button
          ref={submitFormButtonRef}
          type="submit"
          style={{ display: "none" }}
        />
      </Form>
    </Box>
  );
};

export default ServiceRequestForm;

import {
  CoveredAssetsByProductWidgetClient,
  PageLayoutWidgetDto,
} from "@lib/ShiOneClient";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../utils";
import RankingWidget, {
  RankingWidgetContainerHeight,
} from "@widgets/standard/RankingWidget";

import React from "react";
import { SortingOrder } from "../../../../components/mui-data-grid/constants";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import DevicesIcon from "@mui/icons-material/Devices";
import { NoContent, ShadedCellContent, SkeletonWrapper } from "shared-ui";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { NavLink } from "react-router-dom";
import { shiOneUrl } from "@constants";

const numberFormatter = new Intl.NumberFormat("en-US");
function getGridDefinitions(
  loading: boolean,
  error: boolean,
  totalAssets: number
) {
  return {
    sortedColumns: [{ field: "assetsCount", sort: SortingOrder.Descending }],
    columnDefinitions: [
      {
        field: "product",
        headerName: "Product",
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SkeletonWrapper loading={loading || error}>
              <NavLink
                to={AppendIfPrefixExists(shiOneUrl.assets.assetInventory)}
                state={{
                  productId: params.value,
                }}
              >
                {params.value}
              </NavLink>
            </SkeletonWrapper>
          );
        },
      },
      {
        field: "manufacturer",
        headerName: "Manufacturer",
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <SkeletonWrapper loading={loading || error}>
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {params.value}
              </span>
            </SkeletonWrapper>
          );
        },
      },
      {
        field: "assetsCount",
        headerName: "Assets",
        minWidth: 150,
        renderCell: (params: GridRenderCellParams) => {
          const totalRows = params.api.getRowsCount();
          const value =
            typeof params?.value === "number"
              ? numberFormatter.format(params?.value)
              : params?.value;
          return (
            <SkeletonWrapper loading={loading || error}>
              <ShadedCellContent
                index={params.row.identifier}
                value={`${value} (${getPercentage(
                  params?.value,
                  totalAssets
                )}%)`}
                numberOfRows={totalRows}
                rgbNumber={"29, 221, 141"}
              />
            </SkeletonWrapper>
          );
        },
      },
    ],
  };
}

function useGetCoveredAssetsByProductData() {
  const api: CoveredAssetsByProductWidgetClient = useApi(
    CoveredAssetsByProductWidgetClient
  );

  return useQuery({
    queryKey: ["covered-assets-by-products-rankings-widget"],
    queryFn: () => api.getCoveredAssetsByProductData(),
  });
}

function getPercentage(
  data: number | undefined,
  totalCount: number | undefined
): string {
  if (data === undefined || totalCount === undefined) {
    return "--";
  }

  return ((data / totalCount) * 100).toFixed(2);
}

export function CoveredAssetsByProductWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const {
    data: response,
    isLoading,
    isError,
  } = useGetCoveredAssetsByProductData();

  return (
    <RankingWidget
      pageLayoutWidget={pageLayoutWidget}
      data={response?.data?.products ?? []}
      gridDefinitions={getGridDefinitions(
        isLoading,
        isError,
        response?.data?.totalCoveredAssets ?? 0
      )}
      getRowId={(row) => row.identifier}
      rowExpandLookup={undefined}
      noDataElement={
        <NoContent
          header={"No Assets"}
          body={"You have no assets to view at the moment"}
          actionText={""}
          icon={<DevicesIcon />}
          containerHeight={RankingWidgetContainerHeight}
        />
      }
      loading={isLoading}
    />
  );
}

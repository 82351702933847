import {
  Api,
  Apps,
  AssuredWorkload,
  CheckCircle,
  Cloud,
  CrisisAlert,
  Fingerprint,
  Folder,
  Help,
  Info,
  Lock,
  RemoveCircle,
  RuleFolder,
  Security,
  Storage,
  VpnLock,
  Work,
} from "@mui/icons-material";
import React from "react";
import {
  aiReadiness,
  maturityLevels,
  sprCategories,
} from "@features/assessments-feature/constants";
import { maturityLevelColors } from "@features/assessments-feature/utils/assessmentsColors";

export const getMaturityChartCategoryIcon = (name: string) => {
  const categoryIconStyle = { paddingRight: 1, color: "text.secondary" };

  switch (name) {
    case sprCategories.applications:
      return <Apps sx={categoryIconStyle} />;
    case sprCategories.cloud:
      return <Cloud sx={categoryIconStyle} />;
    case sprCategories.dataSecurity:
      return <RuleFolder sx={categoryIconStyle} />;
    case sprCategories.endUser:
      return <Api sx={categoryIconStyle} />;
    case sprCategories.governanceCompliance:
      return <AssuredWorkload sx={categoryIconStyle} />;
    case sprCategories.identity:
      return <Fingerprint sx={categoryIconStyle} />;
    case sprCategories.monitoringAlerting:
      return <CrisisAlert sx={categoryIconStyle} />;
    case sprCategories.network:
      return <VpnLock sx={categoryIconStyle} />;
    case sprCategories.operations:
    case aiReadiness.security:
      return <Security sx={categoryIconStyle} />;
    case aiReadiness.data:
      return <Folder sx={categoryIconStyle} />;
    case aiReadiness.infrastructure:
      return <Storage sx={categoryIconStyle} />;
    case aiReadiness.business:
      return <Work sx={categoryIconStyle} />;
    default:
      return <Lock sx={categoryIconStyle} />;
  }
};

export const getMaturityIcon = (maturity: string, addToIconStyle?: {}) => {
  const maturityIconStyle = {
    marginRight: 1,
    ...addToIconStyle,
    color: maturityLevelColors[maturity],
  };

  switch (maturity) {
    case maturityLevels.optimized:
    case maturityLevels.operational:
      return <CheckCircle sx={maturityIconStyle} />;
    case maturityLevels.installed:
    case maturityLevels.functional:
    case maturityLevels.evaluating:
      return <Info sx={maturityIconStyle} />;
    case maturityLevels.gap:
      return <Help sx={maturityIconStyle} />;
    default:
      return (
        <RemoveCircle
          sx={{ ...maturityIconStyle, color: maturityLevelColors.Unknown }}
        />
      );
  }
};

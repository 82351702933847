import MapIcon from "@mui/icons-material/Map";
import React from "react";
import { NavItemType } from "../../NavigationTypes";
export function RoadmapsMenu(hasRoadmapsPermission: boolean): NavItemType {
  return {
    id: "shiRoadmaps",
    type: "Link",
    displayName: "Roadmaps",
    path: "/roadmaps",
    icon: <MapIcon />,
    visible: hasRoadmapsPermission,
  };
}

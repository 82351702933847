import { useContext, useEffect, useState } from "react";
import { ActiveReportStore, EmbeddedPowerBiStore } from "../stores";
import {
  defaultAvailableIntervals,
  getDefaultEmbedConfig,
  getEmbedConfigSettings,
} from "../helpers";
import { FiltersOperations, IFilter } from "powerbi-models";
import { PbiContext } from "../components/PbiContext";
import { useEmbeddedPowerBiWebWorker } from "./useEmbedPowerBiWebWorker";

export const useEmbedPowerBi = () => {
  const { usePbiHook, pbiTelemetryProps } = useContext(PbiContext);
  useEmbeddedPowerBiWebWorker();

  const [
    reportEmbeddedPowerBiParams,
    setAvailableIntervals,
    setDefaultBookmark,
    overrideTheme,
    hideFilters,
  ] = EmbeddedPowerBiStore((s) => [
    s.reportEmbeddedPowerBiParams,
    s.setAvailableIntervals,
    s.setDefaultBookmark,
    s.overrideTheme,
    s.hideFilters,
  ]);

  const [
    ActiveReportParams,
    ActiveReportFilterDate,
    ActiveEmbeddedReport,
    ActiveEmbedConfig,
    ActiveReportId,
    ActivePagesAreLoading,
  ] = ActiveReportStore((s) => [
    s.activeReportParams,
    s.activeReportFilterDate,
    s.activeEmbeddedReport,
    s.activeEmbedConfig,
    s.activeReportId,
    s.activePagesAreLoading,
    s.activeReportIsLoading,
  ]);

  const [
    setActiveEmbedConfig,
    setActiveEmbeddedReport,
    setActiveReportParams,
    setActiveReportIsLoading,
    setActivePagesAreLoading,
    setCurrentActivePage,
  ] = ActiveReportStore((s) => [
    s.setActiveEmbedConfig,
    s.setActiveEmbeddedReport,
    s.setActiveReportParams,
    s.setActiveReportIsLoading,
    s.setActivePagesAreLoading,
    s.setCurrentActivePage,
  ]);

  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [baseFilters, setBaseFilters] = useState<IFilter[]>([]);
  const {
    data: embedReport,
    isSuccess,
    isLoading,
  } = usePbiHook(
    ActiveReportParams ?? {
      reportId: "",
      filter: "",
      reportName: "",
    },
  );

  useEffect(() => {
    setActiveReportIsLoading(true);
    fillActiveConfig();
    return () => {
      setActiveEmbeddedReport(undefined);
      setActiveEmbedConfig(undefined);
      setActiveReportIsLoading(false);
    };
  }, [ActiveReportId]);

  useEffect(() => {
    if (isSuccess) {
      fillEmbedReport().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ActivePagesAreLoading, embedReport]);

  useEffect(() => {
    if (baseFilters.length > 0) {
      applyFilters().then();
    }
  }, [baseFilters, ActiveReportFilterDate]);

  useEffect(() => {
    if (ActiveEmbeddedReport) {
      onFullScreen(fullScreenMode, ActiveEmbeddedReport, hideFilters).then();
    }
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [fullScreenMode]);

  const fillActiveConfig = () => {
    if (!ActiveReportId) return;
    const config = reportEmbeddedPowerBiParams?.find(
      (r) => r.reportId === ActiveReportId,
    );
    if (!config) return;
    setActiveReportParams(config);
    config.intervals
      ? setAvailableIntervals(config.intervals)
      : setAvailableIntervals(defaultAvailableIntervals);
  };

  const fillEmbedReport = async () => {
    if (embedReport) {
      const embedConfig = getDefaultEmbedConfig(
        embedReport,
        false,
        overrideTheme,
        hideFilters,
      );
      setActiveEmbedConfig(embedConfig);
    }
  };

  const setFilters = async (report: Report) => {
    let baseFilters: IFilter[] = [];
    if (report) {
      const filters = await report.getFilters();
      //@ts-ignore
      baseFilters = filters.filter((f) => f.target?.table !== "Dates");
      await report?.setFilters(baseFilters);
      setBaseFilters(baseFilters);
    }
  };

  const applyFilters = async () => {
    if (ActiveReportFilterDate) {
      await ActiveEmbeddedReport?.updateFilters(FiltersOperations.Replace, [
        ActiveReportFilterDate.toJSON(),
      ] as IFilter[]);
    }
  };

  const captureDefaultBookmark = async (activeEmbeddedReport: Report) => {
    const bookmarksManager = activeEmbeddedReport?.bookmarksManager;

    const capturedBookmark = await bookmarksManager?.capture({
      personalizeVisuals:
        ActiveReportParams?.bookmarkPersonalizedVisual ?? false,
      allPages: true,
    });

    setDefaultBookmark(capturedBookmark);
  };
  const handleFullScreenChange = () => {
    if (!document.fullscreenElement) {
      setFullScreenMode(false);
    }
  };
  const onLoaded = (e: any, entity: any) => {
    setActiveReportIsLoading(false);
    setActivePagesAreLoading(true);
    if (pbiTelemetryProps?.trackLoaded) {
      pbiTelemetryProps?.trackLoaded(e, entity);
    }
    captureDefaultBookmark(entity).then();
    setFilters(entity).then();
  };

  const onFullScreen = async (
    fullScreenMode: boolean,
    activeEmbeddedReport: Report | undefined,
    hideFilters = false,
  ) => {
    if (ActiveEmbeddedReport && fullScreenMode) {
      ActiveEmbeddedReport.fullscreen();
    }
    const fullScreenModeSettings = getEmbedConfigSettings(
      false,
      fullScreenMode,
      overrideTheme,
      hideFilters,
    );
    await activeEmbeddedReport?.updateSettings(fullScreenModeSettings);
  };

  const onPageChange = async (e: any, entity: any) => {
    const activePage = await e?.detail.newPage;
    if (activePage) {
      setCurrentActivePage(activePage);
    }
    if (pbiTelemetryProps?.trackPageChanged) {
      pbiTelemetryProps?.trackPageChanged(e, entity);
    }
  };

  return {
    isLoading,
    isSuccess,
    ActiveEmbeddedReport,
    ActiveEmbedConfig,
    setFullScreenMode,
    fullScreenMode,
    onLoaded,
    onPageChange,
    setActiveEmbeddedReport,
  };
};

export const shiUserEmailDomains = ["@shi.com", "@stratascale.com"];

const integrationTypeIntegerMap = new Map();
integrationTypeIntegerMap.set(0, "Unknown");
integrationTypeIntegerMap.set(1, "AwsCompliance");
integrationTypeIntegerMap.set(2, "AzureCompliance");
integrationTypeIntegerMap.set(4, "AzurePlanReport");
integrationTypeIntegerMap.set(5, "AwsSimpleRecommendations");
integrationTypeIntegerMap.set(6, "AwsBillingReport");
integrationTypeIntegerMap.set(7, "AzureSimpleRecommendations");
integrationTypeIntegerMap.set(8, "AzureBackups");
integrationTypeIntegerMap.set(11, "GcpUsageReport");
integrationTypeIntegerMap.set(13, "AzureDirectConsumption");
integrationTypeIntegerMap.set(14, "DatadogUsageReport");

export const shiOneUrl = {
  assets: {
    assetInventory: "/assets/asset-inventory",
    productCatalogProducts: "/assets/product-catalog/products",
    lifecycle: "/assets/product-catalog/lifecycle",
    locations: "/assets/locations",
    vendorContracts: "/assets/vendor-contracts",
  },
  cloud: {
    tagGroups: "/cloud/tag-groups",
  },
  assessments: {
    spr: "/assessments/security-posture-review",
    aiReadiness: "/assessments/ai-readiness",
  },
};
export const GlobalConstants = {
  integrationTypeIntegerMap,
  uuidNamespace: "574856a8-a1e6-11eb-bcbc-0242ac130002",
  autotaskSyncResourceId: 29683146,
  shiAutotaskAccountId: 676,
  activeUser: 1,
  inactiveUser: 0,
  projectStatus: {
    inactive: 0,
    new: 1,
    inProgress: 2,
    onHold: 3,
    changeOrder: 4,
    complete: 5,
    waitingParts: 6,
    waitingCustomer: 7,
    atRisk: 8,
  },
  ticketStatus: {
    customerNoteAdded: 19,
    complete: 5,
    inactive: 16,
    new: 1,
    inProgress: 8,
    onHold: 17,
  },
  fullTicketStatus: {
    "Customer Note Added": 19,
    Complete: 5,
    Inactive: 16,
    New: 1,
    "In Progress": 8,
    "On Hold": 17,
  },
  fullTicketStatusReverse: {
    19: "Customer Note Added",
    5: "Complete",
    16: "Inactive",
    1: "New",
    8: "In Progress",
    17: "On Hold",
  },
  ticketType: {
    serviceRequest: 1,
    incident: 2,
    changeRequest: 4,
  },
  messages: {
    severity: {
      success: "success",
      error: "error",
      warning: "warning",
      info: "info",
    },
  },
  integrationType: {
    awsCompliance: "AwsCompliance",
    awsGovernanceReports: "AwsGovernanceReports",
    azureCompliance: "AzureCompliance",
    awsResourceExplorer: "AwsResourceExplorer",
    azureGovernanceReports: "AzureGovernanceReports",
    azurePlanReport: "AzurePlanReport",
    awsSimpleRecommendations: "AwsSimpleRecommendations",
    awsBillingReport: "AwsBillingReport",
    azureSimpleRecommendations: "AzureSimpleRecommendations",
    azureBackups: "AzureBackups",
    rackAndStack: "RackAndStack",
    gcpUsageReport: "GcpUsageReport",
    ciscoReadyReport: "CiscoReadyReport",
    ciscoSNTC: "CiscoSNTC",
    ciscoContractCoverage: "CiscoContractCoverage",
    ciscoSubscriptionManagement: "CiscoSubscriptionManagement",
    ciscoEnterpriseAgreement: "CiscoEnterpriseAgreement",
    ciscoMeraki: "CiscoMeraki",
    azureDirectConsumption: "AzureDirectConsumption",
    datadogUsageReport: "DatadogUsageReport",
    azureDevOpsReport: "AzureDevOpsReport",
    idcsReport: "IdcsReport",
    hardwareLifecycleManagement: "HardwareLifecycleManagement",
    assetManagement: "AssetManagement",
    dattoRmm: "DattoRMM",
    selfServiceReporting: "SelfServiceReporting",
    azureResourceGraph: "AzureResourceGraph",
    gcpCloudAssetInventory: "GcpCloudAssetInventory",
    catalyst: "Catalyst",
    securityPostureReview: "SecurityPostureReview",
    aiReadiness: "AIReadiness",
    installBaseAnalysis: "InstallBaseAnalysis",
    microsoftAdoptionAssessment: "MicrosoftAdoptionAssessment",
    devOpsPostureReview: "DevOpsPostureReview",
  },
  successPlanDocumentType: {
    attachment: "Attachment",
    note: "Note",
    hyperlink: "Hyperlink",
    image: "Image",
  },
  successPlanTabNames: {
    activities: "Activities",
    customerProfile: "Customer Profile",
  },
  reportPageStatus: {
    loading: -1,
    noReports: -2,
    defaultItemIndex: 0,
  },
  reportIntervals: {
    lastMonth: "LastMonth",
    lastThreeMonths: "LastThreeMonths",
    lastQuarter: "LastQuarter",
    lastSixMonths: "LastSixMonths",
    yearToDate: "YearToDate",
    yearOverYear: "YearOverYear",
    customRange: "CustomRange",
    customRangeWithDays: "CustomRangeWithDays",
  },
  successPlanPrograms: {
    Azure: 1,
    AWS: 2,
    GCP: 3,
    "Microsoft 3 Year (old)": 4,
    "Microsoft 5 Year": 5,
    "Storage 3 Year": 6,
    "Microsoft 3 Year": 7,
    "AWS Adoption - Internal": 8,
  },
  fetchStatus: {
    idle: "idle",
    complete: "complete",
    error: "error",
    loading: "loading",
  },
  menuItems: {
    all: "All",
  },
  awsRecommendations: {
    trustedAdvisor: "Trusted Advisor",
    savingsPlans: "Savings Plans",
    rightsizing: "Rightsizing",
    reservations: "Reservations",
    costExplorer: "Cost Explorer",
    demoPayerAccountId: "000000000000",
  },
  trustedAdvisor: {
    costOptimizing: "cost_optimizing",
    performance: "performance",
    security: "security",
    faultTolerance: "fault_tolerance",
    serviceLimits: "service_limits",
  },
  azureRecommendations: {
    cost: "Cost",
    operationalExcellence: "OperationalExcellence",
    security: "Security",
    performance: "Performance",
    highAvailability: "HighAvailability",
  },
  pageSize: {
    thirty: 30,
    fifty: 50,
  },
  contractCategory: {
    aws: 29,
    flexibleServiceAgreement: 25,
    gcp: 36,
    managedService: 11,
  },
  contractType: {
    recurringService: 7,
  },
  contractStatus: {
    active: 1,
  },
  serviceLevelAgreement: {
    agileCloudEngineering: 29,
    gcp: 11,
    onboarding: 8,
  },
  ticketPriority: {
    high: "1",
    medium: "2",
    low: "3",
    critical: "4",
  },
  ticketCategory: {
    cisco: "152",
  },
  portalRole: {
    admin: "admin",
  },
  contractDetailsType: {
    generic: "generic",
    ace: "ace",
    fsa: "fsa",
    managedRecurringService: "managedRecurringService",
    recurringService: "recurringService",
  },
  searchPlaceholders: {
    invoice: "Search invoice, purchase order or requisition number...",
  },
  demoAccounts: {
    myModernIT: 31018,
    contoso: 32072,
    shiOne: 32071,
  },
  awsRightsizingTypes: {
    modify: "MODIFY",
    terminate: "TERMINATE",
  },
  bookmarkTypes: {
    account: "account",
    contact: "contact",
  },
  ciscoReports: {
    activeContracts: "Active Contracts",
    fullInventory: "Full Inventory",
    lifecycle: "Life Cycle",
    siteLocation: "Site Location",
  },
  ciscoPropensityTabs: {
    overview: "Overview",
    byCustomer: "By Customer",
    byRankings: "Rankings",
  },
  supportOptionUserInputs: {
    input: "Text",
    textArea: "Text Area",
  },
  storageAccountUrls: {
    blueprints: {
      production:
        "https://shionestorage.blob.core.windows.net/shi-one-blueprints/shi_one_manifest.json",
      integration:
        "https://shionestorage.blob.core.windows.net/shi-one-blueprints-int/shi_one_manifest.json",
    },
    labsDocuments: {
      multiCloudLab:
        "https://shionestorage.blob.core.windows.net/shione/ASG-Labs_Brochure.pdf",
    },
    filePond: {
      successPlanDocumentsContainer: "shione-success-plans-attachments",
    },
    images: {
      shiOneLogo:
        "https://shionestorage.blob.core.windows.net/shione/SHI_ONE_LOGO.png",
      shiOneRebrand:
        "https://shionestorage.blob.core.windows.net/shione/shi-logo-rebrand.png",
      shiOneRebrandSquare:
        "https://shionestorage.blob.core.windows.net/shione/shi-logo-rebrand.png",
      shiOneRebrandFavicon:
        "https://shionestorage.blob.core.windows.net/shione/shi-favicon-rebrand.png",
      catalystLogo:
        "https://shionestorage.blob.core.windows.net/shione/Catalyst-Square.png",
    },
    demoImages: {
      vigilantCloud:
        "https://shionestorage.blob.core.windows.net/shione/demo-vigilant-cloud.png",
      multiCloud:
        "https://shionestorage.blob.core.windows.net/shione/demo-multi-cloud.jpeg",
      everything:
        "https://shionestorage.blob.core.windows.net/shione/Everything-Demo.jpg",
      assetManagement:
        "https://shionestorage.blob.core.windows.net/shione/Assets-Demo.jpg",
      cisco:
        "https://shionestorage.blob.core.windows.net/shione/ciscoDemoCard.png",
    },
  },
  preferredContactMethod: {
    phone: "Phone",
    email: "Email",
  },
  environments: {
    development: "dev",
    integration: "int",
    staging: "staging",
    production: "prod",
    demo: "demo",
  },
  dynamicTagTypes: {
    userLocationName: "userLocationName",
    tag: "tag",
    note: "note",
  },
  dynamicTagSources: {
    assetInventory: "asset-inventory",
    assetLocations: "asset-locations",
  },
  crud: {
    create: "create",
    update: "update",
    delete: "delete",
  },
  serviceRequestForm: {
    submissionType: {
      ticket: "Ticket",
    },
  },
  awsGeneralAccountsPayerID: "980668552438",
  costAndUsage: "Cost and Usage",
  activate: "Activate",
  deactivate: "Deactivate",
  supportTicketPageNames: {
    allRequests: "All Requests",
    myRequests: "My Requests",
    secondaryContact: "Shared with Me",
  },
  supportTicketPage: {
    allRequests: 0,
    myRequests: 1,
    secondaryContact: 2,
  },
  serviceRequestTabCountKeys: {
    allServiceRequestsCount: "allServiceRequestsCount",
    myServiceRequestsCount: "myServiceRequestsCount",
    secondaryServiceRequestsCount: "secondaryServiceRequestsCount",
  },
  integrationStatuses: {
    error: 0,
    disabled: 1,
    pending: 2,
    new: 3,
    success: 4,
  },
  knownPaths: {
    supportCenter_Requests: "/support-center/requests",
    supportCenter_NewRequest: "/support-center/new-request",
    supportCenter_NewUserRequest:
      "/support-center/new-request/service-request/create-user",
  },
  autoTaskKnownIssueTypes: {
    shi_one_portal: 53,
  },
  autoTaskKnownSubIssueTypes: {
    not_listed: 783,
  },
  mimeTypes: {
    csv: "text/csv",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  ServiceRequestSubmissionType: {
    Submit: "Submit",
    SubmitForApproval: "SubmitForApproval",
  },
  ServiceRequestSubmissionTypeId: {
    Submit: 0,
    SubmitForApproval: 1,
  },
  SubmissionType: {
    FullInventory: "FullInventory",
    LifeCycle: "LifeCycle",
    SiteLocation: "SiteLocation",
    Ticket: "Ticket",
  },
  SubmissionTypeId: {
    FullInventory: 1,
    LifeCycle: 2,
    SiteLocation: 3,
    Ticket: 4,
  },
  ServiceRequestType: {
    Default: "Default",
    PagerDuty: "PagerDuty",
    CloudOnboarding: "CloudOnboarding",
    ShiOneServiceRequest: "ShiOneServiceRequest",
    HardwareLifecycleManagement: "HardwareLifecycleManagement",
    Microsoft: "Microsoft",
    WarehousePickOrder: "WarehousePickOrder",
    SoftwareRequest: "SoftwareRequest",
  },
  ServiceRequestTypeId: {
    Default: 0,
    PagerDuty: 1,
    CloudOnboarding: 2,
    ShiOneServiceRequest: 3,
    HardwareLifecycleManagement: 4,
    Microsoft: 5,
    WarehousePickOrder: 6,
    SoftwareRequest: 7,
  },
  ServiceRequestActionToState: {
    New: "New",
    Save: "Open",
    Close: "Closed",
    Reject: "Rejected",
  },
  MuiGridColumnTypes: {
    string: "string",
    number: "number",
    date: "date",
    dateTime: "dateTime",
    boolean: "boolean",
    singleSelect: "singleSelect",
    actions: "actions",
  },
  MuiGridFormats: {
    dateFormat: "M/d/yyyy",
    messageDateFormat: "M/d/yyyy • hh:mma",
  },
  WidgetTypes: {
    keyInfoTiles: "keyInfoTiles",
    summary: "summary",
    barChart: "barChart",
    dataTable: "dataTable",
    custom: "custom",
  },
  FindingComplianceStatuses: {
    failed: "FAILED",
    passed: "PASSED",
    warning: "WARNING",
  },
  ArchbeeExternalUrl: "https://docs.one.shi.com",
  portalName: {
    customerSuccess: "Customer Success",
    customerExperience: "Customer Experience",
  },
};

export default GlobalConstants;

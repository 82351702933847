import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Chart, Plugin } from "chart.js";

const useAdjustCategoriesChartPositions = () => {
  const labelPositionRef = useRef<number[]>([]);
  const labelHeightRef = useRef<number>(0);
  const labelWidthRef = useRef<string[]>([]);
  const redrawTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isFirstRunRef = useRef(true);

  const [labelPosition, setLabelPosition] = useState<number[]>([]);
  const [labelHeight, setLabelHeight] = useState<number>(0);
  const [labelWidth, setLabelWidth] = useState<string[]>([]);

  const updateLabels = useCallback((chart: Chart) => {
    const xAxis = chart.scales["x"];
    const xList = xAxis.ticks.map((_, index) =>
      xAxis.getPixelForTick(index),
    );
    const labelWidthList = xAxis.ticks.map((_, index) => {
      if (index === 0 || index === xAxis.ticks.length - 1) {
        return "48px";
      }
      return `calc(${100 / xAxis.ticks.length}% - 10px)`;
    });

    const isLabelHeightChanged = labelHeightRef.current !== chart.height - 64;
    const isPosChanged =
      JSON.stringify(xList) !== JSON.stringify(labelPositionRef.current);
    const isLabelWidthChanged =
      JSON.stringify(labelWidthRef.current) !== JSON.stringify(labelWidthList);

    if (isPosChanged) {
      labelPositionRef.current = xList;
      setLabelPosition(xList);
    }
    if (isLabelHeightChanged) {
      labelHeightRef.current = chart.height - 64;
      setLabelHeight(chart.height - 64);
    }
    if (isLabelWidthChanged) {
      labelWidthRef.current = labelWidthList;
      setLabelWidth(labelWidthList);
    }
  }, []);

  const customLabelsPlugin: Plugin<"line"> = useMemo(() => ({
    id: "customLabels",
    afterDraw: (chart) => {
      if(isFirstRunRef.current) {
        isFirstRunRef.current = false;
        updateLabels(chart);
      }
      else{
      if (redrawTimeoutRef.current) {
        clearTimeout(redrawTimeoutRef.current);
      }
      // Throttles the redraw to avoid performance issues
      redrawTimeoutRef.current = setTimeout(() => {
        updateLabels(chart);
      }, 25);
    }
    },
  }), [updateLabels]);

  useEffect(() => {
    return () => {
      if (redrawTimeoutRef.current) {
        clearTimeout(redrawTimeoutRef.current);
      }
    };
  }, []);

  return {
    customLabelsPlugin,
    labelHeight,
    labelPosition,
    labelWidth,
  };
};

export default useAdjustCategoriesChartPositions;


import React, { Suspense, useEffect, useState, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import { assessmentTabIds } from "@features/assessments-feature/constants";
import { Grid, Paper, Tabs } from "@mui/material";
import { AppendIfPrefixExists } from "../../../../../modules/sitePaths";
import { TabExportType } from "../types/TabTypes";
import { assessmentTabsAndExports } from "@features/assessments-feature/utils/assessmentTabsAndExports";
import LoadingOrError from "shared-ui/src/global-layout/LoadingOrError";
import { useAssessmentViewContext } from "@features/assessments-feature/contexts-providers/AssessmentViewContextProvider";
import ShiTab from "shared-ui/src/components/ShiTab";

const Overview = React.lazy(
  () =>
    import(
      "@features/assessments-feature/components/overview/AssessmentOverview"
    ),
);
const MaturityChart = React.lazy(
  () =>
    import(
      "@features/assessments-feature/components/maturity-chart/AssessmentMaturityChart"
    ),
);
const Recommendations = React.lazy(
  () =>
    import(
      "@features/assessments-feature/components/priorities-recommendations/AssessmentPrioritiesRecs"
    ),
);
const PlatformInsights = React.lazy(
  () =>
    import(
      "@features/assessments-feature/components/platform-insights/AssessmentPlatformInsights"
    ),
);
const CapabilityInsights = React.lazy(
  () =>
    import(
      "@features/assessments-feature/components/capability-insights/AssessmentCapabilityInsights"
    ),
);

interface AssessmentViewProps {
  program: string;
}

const AssessmentView = (props: AssessmentViewProps) => {
  const { program } = props;
  const navigate = useNavigate();
  const { tab, assessmentId } = useAssessmentViewContext();
  const [isPending, startTransition] = useTransition();

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    let timeoutId: any;

    if (isPending) {
      timeoutId = setTimeout(() => {
        setShowLoading(true);
      }, 80);
    } else {
      setShowLoading(false);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isPending]);

  function handleTabChange(_event: React.SyntheticEvent, newValue: string) {
    startTransition(() => {
      navigate(
        AppendIfPrefixExists(
          assessmentId
            ? `/assessments/${program}/${newValue}?aid=${assessmentId}`
            : `/assessments/${program}/${newValue}`,
        ),
      );
    });
  }

  return (
    <>
      {showLoading ? <LoadingOrError loading /> : null}
      <Grid container direction={"column"} rowGap={2}>
        <Grid item>
          <Paper>
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              value={tab}
              onChange={handleTabChange}
            >
              {assessmentTabsAndExports.map(
                (tab: TabExportType, index: number) => {
                  const key = tab.tabId + index;
                  return (
                    <ShiTab
                      disabled={tab.tabDisabled}
                      value={tab.tabId}
                      key={key}
                      label={tab.tabName}
                    />
                  );
                },
              )}
            </Tabs>
          </Paper>
        </Grid>
        <Grid item>
          <Suspense fallback={<LoadingOrError loading />}>
            {tab === assessmentTabIds.overview && <Overview />}
            {tab === assessmentTabIds.maturityChart && <MaturityChart />}
            {tab === assessmentTabIds.recommendations && <Recommendations />}
            {/*{tab === assessmentTabIds.platformInsights && <PlatformInsights />}*/}
            {/*{tab === assessmentTabIds.capabilityInsights && (*/}
            {/*  <CapabilityInsights />*/}
            {/*)}*/}
          </Suspense>
        </Grid>
      </Grid>
    </>
  );
};

export default AssessmentView;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "store";
import { ClientManagementState } from "./clientManagementTypes";
import {
  getRestAccountsOnboardingStatuses,
  getRestAccountWelcomeLink,
  updateAccountType,
} from "../../../api/clientManagementStore";
import {
  AccountConfiguredStatus,
  AccountTypeUpdate,
  AccountWelcomeLink,
} from "../../../lib/ShiOneClient";
import GlobalConstants from "@constants";

const initialState: ClientManagementState = {
  accountsOnboardingInfoRequestStatus: GlobalConstants.fetchStatus.idle,
  accountsOnboardingInfo: [],
  accountWelcomeLinkRequestStatus: GlobalConstants.fetchStatus.idle,
  accountWelcomeLink: {} as AccountWelcomeLink,
  accountWelcomeLinkError: "",
  updateAccountStatus: GlobalConstants.fetchStatus.idle,
};

const clientManagementSlice = createSlice({
  name: "clientManagement",
  initialState,
  reducers: {
    receivingOnboardingStatuses(state) {
      state.accountsOnboardingInfoRequestStatus =
        GlobalConstants.fetchStatus.loading;
    },
    receiveOnboardingStatuses(
      state,
      action: PayloadAction<AccountConfiguredStatus[]>
    ) {
      state.accountsOnboardingInfo = action.payload;
      state.accountsOnboardingInfoRequestStatus =
        GlobalConstants.fetchStatus.complete;
    },
    receivingAccountWelcomeLink(state) {
      state.accountWelcomeLinkRequestStatus =
        GlobalConstants.fetchStatus.loading;
      state.accountWelcomeLink = {} as AccountWelcomeLink;
      state.accountWelcomeLinkError = "";
    },
    receiveAccountWelcomeLink(
      state,
      action: PayloadAction<AccountWelcomeLink>
    ) {
      state.accountWelcomeLinkRequestStatus =
        GlobalConstants.fetchStatus.complete;
      state.accountWelcomeLink = action.payload;
      state.accountWelcomeLinkError = "";
    },
    accountWelcomeLinkError(state, action: PayloadAction<string>) {
      state.accountWelcomeLinkRequestStatus = GlobalConstants.fetchStatus.error;
      state.accountWelcomeLink = {} as AccountWelcomeLink;
      state.accountWelcomeLinkError = action.payload;
    },
    updatingAccount(state) {
      state.updateAccountStatus = GlobalConstants.fetchStatus.loading;
    },
    updateAccountSuccess(state, action: PayloadAction<AccountTypeUpdate>) {
      state.updateAccountStatus = GlobalConstants.fetchStatus.complete;
    },
    updateAccountError(state) {
      state.updateAccountStatus = GlobalConstants.fetchStatus.error;
    },
  },
});

export const getAccountsOnboardingStatuses =
  (accountIds: number[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(clientManagementSlice.actions.receivingOnboardingStatuses());
    const accountsOnboardingInfo = await getRestAccountsOnboardingStatuses(
      accountIds
    );
    dispatch(
      clientManagementSlice.actions.receiveOnboardingStatuses(
        accountsOnboardingInfo
      )
    );
  };

export const getAccountWelcomeLink =
  (linkRequest: AccountWelcomeLink): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(clientManagementSlice.actions.receivingAccountWelcomeLink());
    try {
      const accountWelcomeLink = await getRestAccountWelcomeLink(linkRequest);
      dispatch(
        clientManagementSlice.actions.receiveAccountWelcomeLink(
          accountWelcomeLink
        )
      );
    } catch (error: any) {
      dispatch(
        clientManagementSlice.actions.accountWelcomeLinkError(
          error.response.data
        )
      );
    }
  };

export const updateCustomerAccountType =
  (request: AccountTypeUpdate): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(clientManagementSlice.actions.updatingAccount());
    try {
      const updatedAccount = await updateAccountType(request);
      dispatch(
        clientManagementSlice.actions.updateAccountSuccess(updatedAccount)
      );
    } catch {
      dispatch(clientManagementSlice.actions.updateAccountError());
    }
  };

export default clientManagementSlice.reducer;

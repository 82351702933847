import { PageLayoutWidgetDto } from "@lib/ShiOneClient";

import React, { ReactNode } from "react";
import { WidgetWrapper } from "shared-ui";
import MuiDataGrid from "../../../../components/mui-data-grid/MuiDataGrid";
import { widgetTableTheme } from "shared-ui/src/widgets/framework/widgetTableTheme";
import { numberFormatter } from "@features/assets-feature/utils/assetInventoryFunctions";

export const RankingWidgetContainerHeight = 320;

export default function RankingWidget({
  pageLayoutWidget,
  headerAction,
  data,
  gridDefinitions,
  getRowId,
  treeData,
  rowExpandLookup,
  noDataElement,
  loading,
  height,
  cardActions,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
  headerAction?: ReactNode;
  data: any;
  gridDefinitions: any;
  getRowId?: (row: any) => string;
  treeData?: boolean;
  rowExpandLookup: any;
  noDataElement: ReactNode;
  loading: boolean;
  height?: number | string;
  cardActions?: React.ReactElement;
}) {
  let rankingHeight = height ? height : RankingWidgetContainerHeight;

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      noPadding={true}
      headerAction={headerAction}
      isEmpty={data?.length === 0 && !loading}
      cardActions={cardActions}
    >
      {data?.length > 0 || loading ? (
        <MuiDataGrid
          autoHeight={false}
          loading={loading}
          containerStyle={{ height: rankingHeight }}
          gridData={data ?? []}
          gridDefinitions={gridDefinitions}
          getRowId={getRowId}
          pagination={false}
          hideFooter={true}
          adjustRowHeight={false}
          customTheme={{
            ...widgetTableTheme(),
            "& .MuiDataGrid-cell:has(+ :not(.MuiDataGrid-cell))": {
              padding: 0,
              "& div": {
                paddingRight: ".5rem",
                textAlign: "right",
                justifyContent: "flex-end",
              },
            },
            "& .MuiDataGrid-cell:last-child": {
              padding: 0,
              "& div": {
                paddingRight: ".5rem",
                textAlign: "right",
                justifyContent: "flex-end",
              },
            },
          }}
          treeData={treeData}
          rowExpandLookup={rowExpandLookup}
        />
      ) : (
        noDataElement
      )}
    </WidgetWrapper>
  );
}

export function RankingColoredCell({
  value,
  row,
  totalRecords,
  baseColor,
  showValue = true,
}: {
  value: number;
  row: any;
  count: number;
  totalRecords: number;
  baseColor: string;
  showValue?: boolean;
}) {
  if (row.hierarchy && row.hierarchy.length > 1) {
    baseColor = "255, 255, 255";
  }

  let percentage = ((value / totalRecords) * 100).toFixed(2);
  let ratio = Number((Number(percentage) / 100).toFixed(3));
  let generatedColor = `rgba(${baseColor}, ${ratio * 0.6})`;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        backgroundColor: `${generatedColor}`,
        width: "100%",
        height: "100%",
      }}
    >
      {showValue
        ? `${numberFormatter.format(value)} (${percentage}%)`
        : percentage}
      %
    </div>
  );
}

import { NavItemType } from "./NavigationTypes";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";

export function updateActiveNavGroups(
  navItemId: string,
  navItemChildren: (NavItemType | undefined)[],
  locationPath: string,
  openGroups: Record<string, boolean>,
): boolean {
  return navItemChildren.some((child) => {
    let isGroupActive = false;
    if (child?.path) {
      if (
        locationPath.startsWith(child.path) ||
        locationPath.startsWith(AppendIfPrefixExists(child.path))
      ) {
        isGroupActive = true;
      }
    }

    if (child?.children) {
      isGroupActive = updateActiveNavGroups(
        child.id,
        child.children,
        locationPath,
        openGroups,
      );
    }

    openGroups[navItemId] = isGroupActive;
    return isGroupActive;
  });
}

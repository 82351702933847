import React from "react";
import { NavItemType } from "../../NavigationTypes";
import { servicesItemsToShowType } from "../../NavItemsToShowTypes";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { CatalystMenu } from "./CatalystMenu";

export function CustomerServicesMenuV2(
  servicesItemsToShow: servicesItemsToShowType,
  fsaID: number | undefined,
): NavItemType {
  return {
    id: "services",
    type: "Dashboard",
    displayName: "Services",
    icon: <VolunteerActivismIcon />,
    visible: true,
    path: "/services",
    children: [
      CatalystMenu(servicesItemsToShow.showCatalyst),
      {
        id: "servicesAllProjects",
        type: "Link",
        displayName: "Projects",
        path: "/services/projects",
        visible: servicesItemsToShow.showAllProjects,
      },
      {
        id: "servicesAllContracts",
        type: "Link",
        displayName: "Contracts",
        path: "/services/contracts",
        visible: servicesItemsToShow.showContracts,
      },
      {
        id: "servicesInvoices",
        type: "Link",
        displayName: "Billing",
        path: "/services/billing",
        visible: servicesItemsToShow.showInvoices,
      },
      {
        id: "servicesFSA",
        type: "Link",
        displayName: "Flexible Service Agreement",
        path: "/services/contracts/" + fsaID,
        visible: servicesItemsToShow.showFlexibleServiceAgreement,
      },
    ],
  };
}

import {
  PbiBookmarksProps,
  PbiTelemetryProps,
  PbiWebWorkerProps,
  PowerBiReportCenter,
} from "shared-ui/src/shared-features/report-center";
import { useSelector } from "react-redux";
import {
  deleteRestDeleteBookmark,
  getRestBookmarks,
  postRestAddBookmark,
  putRestUpdateBookmark,
} from "@api/bookmarkStore";
import {
  trackBookmarkApplied,
  trackButtonClicked,
  trackCommandTriggered,
  trackDataHyperlinkClicked,
  trackDataSelected,
  trackError,
  trackFiltersApplied,
  trackLoaded,
  trackPageChanged,
  trackRendered,
  trackRenderingStarted,
  trackSelectionChanged,
  trackVisualClicked,
  trackVisualRendered,
} from "../../../theme/extensions/PowerBiTelemetry";
import { getNewAccessToken } from "@api/reportStore";
import { telemetryTrackEvent } from "../../../functions/telemetry";
import { useGetEmbedParams } from "../shared-features/report-center/hooks";
import { FeatureDefinitions } from "shared-ui/src/utils/FeatureDefinitions";

export const CustomerExperienceReportCenter = (props) => {
  const reportRelatedFeature =
    FeatureDefinitions.ServiceRequests_AccountServiceRequests;

  const pbiBookmarksDefault: PbiBookmarksProps = {
    useCurrentUser: () => {
      return useSelector(
        (state: any) => state.profile.userProfileResponse.userProfile.contact,
      );
    },
    useVirtualAdmin: () => {
      const { virtualAdmin } = useSelector(
        (state: any) => state.profile.userProfileResponse,
      );
      return virtualAdmin;
    },
    getBookmarks: getRestBookmarks,
    postBookmarks: postRestAddBookmark,
    putBookmarks: putRestUpdateBookmark,
    deleteBookmarks: deleteRestDeleteBookmark,
  };

  const pbiDefaultTelemetry: PbiTelemetryProps = {
    trackLoaded: trackLoaded,
    trackPageChanged: trackPageChanged,
    trackRendered: trackRendered,
    trackDataSelected: trackDataSelected,
    trackButtonClicked: trackButtonClicked,
    trackFiltersApplied: trackFiltersApplied,
    trackCommandTriggered: trackCommandTriggered,
    trackBookmarkApplied: trackBookmarkApplied,
    trackDataHyperlinkClicked: trackDataHyperlinkClicked,
    trackVisualRendered: trackVisualRendered,
    trackVisualClicked: trackVisualClicked,
    trackSelectionChanged: trackSelectionChanged,
    trackRenderingStarted: trackRenderingStarted,
    trackError: trackError,
  };

  const pbiDefaultWebWorker: PbiWebWorkerProps = {
    webWorkerGetNewAccessToken: getNewAccessToken,
    telemetryFunc: telemetryTrackEvent,
  };

  return (
    <PowerBiReportCenter
      reportRelatedFeature={reportRelatedFeature}
      header={props?.header}
      reports={props?.reports}
      pbiBookmarksProps={pbiBookmarksDefault}
      usePbiHook={useGetEmbedParams}
      pbiTelemetryProps={pbiDefaultTelemetry}
      webWorkerProps={pbiDefaultWebWorker}
      overrideTheme={props?.overrideTheme}
      hideFilters={props?.hideFilters}
      {...props}
    />
  );
};

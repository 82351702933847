import React from "react";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import SingleDataWithLabels from "../../../components/SingleDataWithLabels";
import { Reviews } from "@mui/icons-material";
import { AssessmentProgram } from "@lib/ShiOneClient";
import PercentageLine from "../../../components/PercentageLine";
import { handleConvertDataToLineVisual } from "@features/assessments-feature/helpers/assessmentOverviewHelpers";
import { SkeletonWrapper } from "shared-ui";
import DemoModeOverlay from "./DemoModeOverlay";
import useAssessmentDemoData from "@features/assessments-feature/hooks/useAssessmentDemoData";
import assessmentViewsMap from "@features/assessments-feature/utils/assessmentViewsMap";
import useGetAssessmentOverview from "@features/assessments-feature/hooks/useGetAssessmentOverview";
import { useAssessmentViewContext } from "@features/assessments-feature/contexts-providers/AssessmentViewContextProvider";

const AssessmentOverviewSummary = () => {
  const { program } = useAssessmentViewContext();
  const assessmentProgram = assessmentViewsMap.get(program ?? "")?.id;
  const { data: sprData, isLoading } =
    useGetAssessmentOverview(assessmentProgram);
  const { isDemoMode } = useAssessmentDemoData();
  const summaryData = sprData?.summary;

  const maturityScorePercentage = summaryData?.maturityScore
    ? Number(((summaryData?.maturityScore / 5) * 100).toFixed(1))
    : 0;

  return (
    <Card sx={{ position: "relative" }}>
      {program && isDemoMode.get(assessmentProgram as AssessmentProgram) && (
        <DemoModeOverlay />
      )}

      <CardHeader
        title={"Summary"}
        titleTypographyProps={{ variant: "body1", textTransform: "uppercase" }}
      />
      <Divider />
      <Box padding={2}>
        <SingleDataWithLabels
          data={`${summaryData?.maturityScore ?? 0} / 5`}
          label={"Maturity Score"}
        />
        <LinearProgress
          variant="determinate"
          value={maturityScorePercentage}
          sx={{ marginTop: 2, height: 11 }}
        />
      </Box>
      <Divider />
      <Box padding={2}>
        <PercentageLine
          loading={isLoading}
          additionalStyles={{ maxHeight: "220px" }}
          visualParts={handleConvertDataToLineVisual(
            summaryData?.capabilityAreas,
          )}
        />
      </Box>
      <Divider />
      <Box padding={2}>
        <Grid container justifyContent={"space-between"} flexWrap={"nowrap"}>
          <Grid item container alignItems={"center"} gap={1}>
            <Reviews color={"action"} />
            <Typography>Recommendations</Typography>
          </Grid>
          <Grid item>
            <SkeletonWrapper loading={isLoading}>
              <Typography variant={"h6"}>
                {summaryData?.recommendations ?? 0}
              </Typography>
            </SkeletonWrapper>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default AssessmentOverviewSummary;
